export const FILE_IMAGE_MIME_TYPE = 'image/';
export const FILE_VIDEO_MIME_TYPE = 'video/';
export const FILE_AUDIO_MIME_TYPE = 'audio/';
export const FILE_PDF_MIME_TYPE = 'application/pdf';
export const FILE_APK_MIME_TYPE = 'application/zip';
export const FILE_IO_STRING_MIME_TYPE = 'text/plain';
export const FILE_CSV_MIME_TYPE = 'text/csv';
export const FILE_EXCEL_MIME_TYPES = [
  'application/vnd.ms-excel',
  'application/vnd-xls',
  'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
  'application/excel',
  'application/x-excel',
  'application/x-msexcel',
  'application/vnd.oasis.opendocument.spreadsheet',
];
export const JSON_MIME_TYPES = [
  'application/json', 
  'application/ld+json'
];

export enum FileTypeEnum {
  IMAGE = 1,
  VIDEO = 2,
  PDF = 3,
  EXCEL = 4,
  APK = 5,
  IO_STRING_WORKSTEP_WIDGET = 6,
  AUDIO = 7,
  CSV = 8,
  JSON = 9,
  OTHER = 10,
}