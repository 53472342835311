import { Component, OnInit, ViewChild, Renderer2, ElementRef, OnDestroy } from '@angular/core';
import { of, Subscription, timer, forkJoin } from 'rxjs';
import * as _ from 'lodash';
import { AEGroupInterface } from '../../groups/interfaces/a-e-group.interface';
import { MatLegacyPaginator as MatPaginator } from '@angular/material/legacy-paginator';
import { MatSort } from '@angular/material/sort';
import { MatLegacyTableDataSource as MatTableDataSource } from '@angular/material/legacy-table';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { ForwardAlarmComponent } from './forward-alarm/forward-alarm.component';
import { catchError, first, tap } from 'rxjs/operators';
import { AlarmsAndEscalationsService } from '../../alarms-and-escalations.service';
import { AlarmEventEnum } from '../../../../../common/alarms-and-escalations/enums/alarm-event.enum';
import { SaveFilterService } from '../../../../../common/shared/services/save-filter.service';
import { currentAlarmFilterKey } from '../../../../../common/filters/constants/filter-key.constants';
import { TableColumnVisibilityService } from '../../../../../common/user-table-settings/services/table-column-visibility.service';
import { UserTableTypeEnum } from '../../../../../common/user-table-settings/enums/user-table-type.enum';
import { UserTablePlaceEnum } from '../../../../../common/user-table-settings/enums/user-table-place.enum';
import * as _memont from 'moment';
import { DurationPipe } from '../../../../../common/shared/pipes/duration.pipe';
const moment = _memont;
import { UpdateReasonComponent } from '../../alarm-details/update-reason/update-reason.component';
import { UserPermissionsEnum } from '../../../../../common/auth/enums/user-permissions.enum';
import { AlarmLoginService } from '../../alarm-login.service';
import { LoginResponseInterface } from '../../../../../common/auth/interfaces/login-response.interface';
import { SomebodySubscribedModalComponent } from '../../somebody-subscribed-modal/somebody-subscribed-modal.component';
import { ActionLoginModalComponent } from '../../action-login-modal/action-login-modal.component';
import { UserInterface } from '../../../../../common/auth/interfaces/user.interface';
import { AuthService } from '../../../../app/auth/auth.service';
import { CommonService } from '../../../../../common/shared/services/common.service';
import { SocketMessageEnum } from '../../../../../common/socket/enums/socket-message.enum';
import { SocketService } from '../../../../app/_services/socket.service';
import { SocketChannel } from '../../../../../common/socket/utils/socket-channel';
import { environment } from '../../../../environments/environment';
import { AlarmPriorityEnum } from '../../../../../common/alarms-and-escalations/enums/alarm-type.enum';
import { UserService } from '../../../user-management/users/user.service';
import { CurrentAlarmFiltersInterface, CurrentAlarmParamsInterface } from '../../../../../common/filters/interfaces/current-alarm-filters.interface';
import { ResizeTableDirective } from "../../../shared/directives/resizeTable.directive";

@Component({
  selector: "app-current-alarms",
  templateUrl: "./current-alarms.component.html",
  styleUrls: ["./current-alarms.component.scss"],
})
export class CurrentAlarmsComponent implements OnInit, OnDestroy {
  @ViewChild(ResizeTableDirective) resizeTableDirective: ResizeTableDirective;

  currentPage = 0;
  groups: AEGroupInterface[] = [];
  displayedColumns: string[] = [];
  permissions: [number] = [UserPermissionsEnum.AEEndAlarmFromDashboardManagement];
  columnsData = [
    {
      name: "colorStatus",
      id: "Status",
      isAlwaysVisible: true,
      isVisible: true,
    },
    {
      name: "status",
      id: "Status text",
      isAlwaysVisible: true,
      isVisible: true,
    },
    {
      name: "id",
      id: "Alarm id",
      isAlwaysVisible: true,
      isVisible: true,
    },
    {
      name: "lineName",
      id: "Production Sector",
      isAlwaysVisible: false,
      isVisible: true,
    },
    {
      name: "stationName",
      id: "Station",
      isAlwaysVisible: false,
      isVisible: true,
    },
    {
      name: "configuredAlarmName",
      id: "Alarm Type",
      isAlwaysVisible: false,
      isVisible: true,
    },
    {
      name: "customFieldsData",
      id: "Custom Fields",
      isAlwaysVisible: false,
      isVisible: false,
    },
    {
      name: "alarmGroupName",
      id: "Current alarm group",
      isAlwaysVisible: false,
      isVisible: true,
    },
    {
      name: "created",
      id: "Start",
      isAlwaysVisible: false,
      isVisible: true,
    },
    {
      name: "duration",
      id: "Duration",
      isAlwaysVisible: false,
      isVisible: true,
    },
    {
      name: "currentUser",
      id: "Current User",
      isAlwaysVisible: false,
      isVisible: true,
    },
    {
      name: "actions",
      id: "Actions",
      isAlwaysVisible: false,
      isVisible: true,
    },
  ];
  dataSource = new MatTableDataSource<any>([]);
  lines = [];
  stations = [];
  configuredAlarms = [];
  users = [];
  priorities = [
    {
      id: AlarmPriorityEnum.LWO,
      label: 'SPA.ALARMS_ESCALATIONS.ALARMS.ALARM_Form.ALARM_LOW_PRIORITY'
    },
    {
      id: AlarmPriorityEnum.MID,
      label: 'SPA.ALARMS_ESCALATIONS.ALARMS.ALARM_Form.ALARM_MIDDLE_PRIORITY'
    },
    {
      id: AlarmPriorityEnum.HIGH,
      label: 'SPA.ALARMS_ESCALATIONS.ALARMS.ALARM_Form.ALARM_HIGH_PRIORITY'
    }
  ];
  filterData: CurrentAlarmFiltersInterface = {
    lines: [],
    stations: [],
    status: [],
    configuredAlarms: [],
    users: [],
    priorities: [],
  };
  filterToSend: CurrentAlarmFiltersInterface = {
    lines: [],
    stations: [],
    status: [],
    configuredAlarms: [],
    users: [],
    priorities: [],
  };
  status = [
    AlarmEventEnum.OCCURRED, 
    AlarmEventEnum.DISORDER_SUPERVISOR_SUBSCRIBED, 
    AlarmEventEnum.DISORDER_SUPERVISOR_FORWARDED, 
    AlarmEventEnum.DISORDER_SUPERVISOR_ESCALATED
  ];
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;
  showFilters = false;
  isStationIdInFilter: boolean = false;
  private currentUser: UserInterface;
  data = [];
  isMobileScreen: boolean;
  private currentTime;
  socketSubs: string[] = [];
  private pollingSubscription: Subscription;
  isFetchingCurrentAlarms: boolean = false;
  apiUrl: string;
  totalData: number;  
  public columns: any[] = [
    { field: 'colorStatus', width: 20, },
    { field: 'status', width: 20, },
    { field: 'id', width: 20, },
    { field: 'lineName', width: 20, },
    { field: 'stationName', width: 20, },
    { field: 'configuredAlarmName', width: 20, },
    { field: 'customFieldsData', width: 20, },
    { field: 'alarmGroupName', width: 20, },
    { field: 'created', width: 20, },
    { field: 'duration', width: 20, },
    { field: 'currentUser', width: 20, },
    { field: 'actions', width: 20, },
  ];

  constructor(
    private matDialog: MatDialog,
    private alarmsAndEscalationsService: AlarmsAndEscalationsService,
    private alarmLoginService: AlarmLoginService,
    private durationPipe: DurationPipe,
    private saveFilterService: SaveFilterService,
    private tableColumnVisibilityService: TableColumnVisibilityService,
    private authService: AuthService,
    private commonService: CommonService,
    private renderer: Renderer2, 
    private el: ElementRef,
    private socketService: SocketService,
    private userService: UserService,
  ) {}
  ngOnInit(): void {
    this.apiUrl = environment.apiUrl;
    this.subscribeToCurrentTime();
    this.authService.currentUser$.subscribe(user => {
      this.currentUser = user;
    });
    this.fetchFiltersData();
    this.fetchUserFilters();

    this.getColumns();

    this.renderer.listen('window', 'resize', (event) => {
      this.getWindowWidth();
    });

    this.getWindowWidth();
  }

  ngAfterViewInit() {
    if(this.paginator) {
      this.paginator.page.subscribe(paginator => this.loadList());
    }
    if(this.sort) {
      this.sort.sortChange.subscribe(sort => this.loadList());
    }
  }

  subscribeToCurrentTime() {
    this.socketService.join(SocketChannel.CURRENT_TIME);
    this.socketSubs.push(this.socketService.on(SocketMessageEnum.TIME, (data: any) => {
        this.currentTime = data.current_time;
    }));
  }

  fetchFiltersData() {
    const observables = [
      this.alarmsAndEscalationsService.fetchLines(),
      this.alarmsAndEscalationsService.fetchStations(),
      this.alarmsAndEscalationsService.getAllConfiguredAlarm(),
      this.userService.getAllUsers(),
    ];
    forkJoin(observables).subscribe(([lines, stations, configuredAlarms, users]) => {
      this.lines = lines;
      this.stations = stations;
      this.configuredAlarms = configuredAlarms;
      this.users = users;
    });
  }

  fetchUserFilters() {
    this.saveFilterService
      .getFiltersUsingKey(currentAlarmFilterKey)
      .subscribe((filters) => {
        this.filterData = {...filters};
        this.filterToSend = {...filters};
        this.loadList();
      }, () => {
        this.loadList();
      }
    );
  }

  ngOnDestroy(): void {
    this.socketSubs.forEach(s => this.socketService.off(s));
    this.stopPolling();
  }


  getWindowWidth() {
    this.isMobileScreen = this.renderer.parentNode(this.el.nativeElement).ownerDocument.defaultView.innerWidth <= 768
  }

  async endAlarm(alarm) {
    let autoLoginResponse: any = await this.alarmLoginService.autoLogin(alarm);
    
    if (autoLoginResponse === null) {
      return;
    }

    if (autoLoginResponse === false) {
        this.openDisorderLoginModal(alarm);
        return;
    }
    
    this.decideWhichModalOpen(autoLoginResponse as LoginResponseInterface, alarm);
    
  }
  
  decideWhichModalOpen(result: LoginResponseInterface, alarm, openForwardModal: boolean = false) {
    if (result && result.token) {
        if (result.alarmAlreadySubscribed) {
          this.openAlreadySubscribedModal(result.token, result.alarmAlreadySubscribed.user, alarm, openForwardModal);
        } else {
          this.openLastModal(result.token, alarm, openForwardModal);
        }
    }
  }

  private openLastModal(token: string, alarm, openForwardModal: boolean) {
    if (openForwardModal) {
        this.openForwardModal(token, alarm);
    } else {
      this.openDisorderSolveModal(token, alarm);
    }
  }
  
  private openAlreadySubscribedModal(token: string, userAlreadySubscribed: string, alarm, openForwardModal: boolean = false) {
    const dialogRef = this.matDialog.open(SomebodySubscribedModalComponent, {
        width: '90%',
        maxWidth: '1000px',
        panelClass: 'somebody-subscribed',
        data: {
            token: token,
            lineId: alarm.line.id,
            stationId: alarm.station.id,
            alarmId: alarm.id,
            userAlreadySubscribed: userAlreadySubscribed
        }
    });

    dialogRef.afterClosed().subscribe((result) => {
        if (result) {
            this.alarmsAndEscalationsService.reSubscribe(alarm.id, alarm.notificationId, token).subscribe(() => {
                this.openLastModal(token, alarm, openForwardModal);
            })
        } else {
            this.logoutFromDisorderResolving(token, alarm);
        }
    })
  }

  private openDisorderSolveModal(token, alarm) {
    this.matDialog.open(UpdateReasonComponent, {
        data: {
          ...alarm,
          token: token,
          modalType: 'endAlarm'
        },
        width: '550px',
    }).afterClosed().pipe().subscribe(res => {
        if (res) {
          this.loadList();
        }
    });
  }

  private openForwardModal(token, alarm) {
    this.matDialog.open(ForwardAlarmComponent, {
      data: alarm,
      width: "350px",
    }).afterClosed().pipe(first()).subscribe((res) => {
      if (res) {
        this.loadList();
      }
    });
  }

  private openDisorderLoginModal(alarm, openForwardModal: boolean = false) {
    const dialogRef = this.matDialog.open(ActionLoginModalComponent, {
        width: '550px',
        maxWidth: '1000px',
        panelClass: 'login-modal',
        data: {
            extraLoginData: {
                alarmId: alarm.id,
                stationId: alarm.station.id,
                productItemId: null,
                isLoginForForwardAlarm: openForwardModal
            },
            endpoint: '/api/alarm/login',
            barcodeEndpoint: '/api/alarm/login/code',
            permissions: [UserPermissionsEnum.AESupervisor] 
        }
    });

    dialogRef.afterClosed().subscribe((result: LoginResponseInterface) => {
        this.decideWhichModalOpen(result, alarm, openForwardModal);
    });
  }

  logoutFromDisorderResolving(token: string, alarm) {
    const data = {
      alarmId: alarm.id,
      stationId: alarm.station.id,
      productItemId: null
    };
    this.alarmsAndEscalationsService.logout(data, token);
}

  getColumns() {
    this.tableColumnVisibilityService.loadTableMetaData(UserTableTypeEnum.CURRENT_ALARMS,UserTablePlaceEnum.SPA).subscribe((result:any)=>{
        if(result?.length > 0 && result.length === this.columnsData.length){
            this.columnsData = result;
        }
        this.setColumns();
    })
  }

  setColumns(){
      this.displayedColumns = [];
      this.columnsData.forEach((element) => {
          if (element.isVisible) {
            this.displayedColumns.push(element.name);
          }
      });
  }

  columnsChanged() {    
      let data = {
          tableTypeEnum: UserTableTypeEnum.CURRENT_ALARMS,
          placeEnum: UserTablePlaceEnum.SPA,
          columns: this.columnsData
      }
      this.tableColumnVisibilityService.saveTableMetaData(data).subscribe((result: any)=>{
          this.columnsData = result;
          this.setColumns();
      })
  }

  // Load current alarms data
  loadList = () => {
    // Return if API calling is happening
    if (this.isFetchingCurrentAlarms) return;
  
    this.isFetchingCurrentAlarms = true;
    const params: CurrentAlarmParamsInterface = {
      sortCol: this.sort?.active || 'id',
      sortDir: this.sort?.direction || 'DESC',
      pageNo: this.paginator?.pageIndex || 0,
      pageSize: this.paginator?.pageSize || 10
    };
  
    this.alarmsAndEscalationsService
      .adminGetAllCurrentAlarms(params, this.filterToSend)
      .pipe(
        tap((response) => {
          this.isFetchingCurrentAlarms = false;
  
          this.dataSource = new MatTableDataSource<any>(response.results);
          this.data = response.results;
          this.totalData = response.total;
  
          this.startPolling();
        }),
        catchError((error) => {
          console.error('Error fetching alarms:', error);
          
          this.isFetchingCurrentAlarms = false;
          this.startPolling();
  
          // Return an empty observable to continue
          return of([]);
        })
      )
      .subscribe();
  };

  // Start polling after getting the response
  startPolling() {
    this.stopPolling(); 

    // Set up a timer that emits every 5 seconds
    this.pollingSubscription = timer(5000).subscribe(() => {
      this.loadList();
    });
  }

  // Stop polling and unsubscribe 
  stopPolling() {
    if (this.pollingSubscription) {
      this.pollingSubscription.unsubscribe();
      this.pollingSubscription = null; 
    }
  }
  
  accept(element) {
    this.alarmsAndEscalationsService
      .acceptAlarm(element.id, element.notificationId)
      .subscribe((res) => {
        this.loadList();
      });
  }

  unSubscribeAlarm(element) {
    this.alarmsAndEscalationsService
      .unSubscribeAlarm(element.id, element.notificationId)
      .subscribe((res) => {
        this.loadList();
      });
  }

  async forward(element) {
    let autoLoginResponse: any = await this.alarmLoginService.autoLogin(element, true);

    if (autoLoginResponse === null) {
      return;
    }

    if (autoLoginResponse === false) {
        this.openDisorderLoginModal(element, true);
        return;
    }

    this.decideWhichModalOpen(autoLoginResponse as LoginResponseInterface, element, true);
  }

  changeFilters() {
    
    for (const [key, value] of Object.entries(this.filterData)) {
        this.filterToSend[key] = _.isArray(value) ?  value.filter(i => i !== 'all') : value;
    }

    this.saveFilterService.createObject(
      currentAlarmFilterKey,
      this.filterToSend
    );
    this.loadList();
  }

  calculateDuration(cd) {
    return this.calculateDurationFromDates(cd);
  }

  calculateDurationFromDates = (begin) => {
    const begin1 = moment.utc(new Date(begin), "HH:mm:ss");
    let end = moment.utc(new Date(this.currentTime), "HH:mm:ss");

    if (end.isSameOrBefore(begin1)) {
      end = end.add(1, "day");
    }

    let dif = moment.duration(end.diff(begin)).asSeconds();
    return this.durationPipe.transform(dif);
  };

  resetFilters() {
    this.filterToSend = {
      lines: [], 
      stations: [], 
      status: [], 
      configuredAlarms: [], 
      users: [], 
      priorities: []
    };
    this.filterData = {
      lines: [], 
      stations: [], 
      status: [], 
      configuredAlarms: [], 
      users: [], 
      priorities: []
    };

    this.saveFilterService.createObject(
      currentAlarmFilterKey,
      this.filterToSend
    );
    this.loadList();
  }

  toggleSelection(filter: string) {
    const filterData = {};
    const data = {
      lines: this.lines,
      stations: this.stations,
      status: this.status,
      configuredAlarms: this.configuredAlarms,
      users: this.users,
      priorities: this.priorities,
    };
    const items = data[filter].map(i => {
      switch(filter) {
        case 'status': 
          return i;
        default: 
          return i.id;
      }
    });

    for (const [key, value] of Object.entries(this.filterData)) {
      filterData[key] = value;
    }
    filterData[filter] = filterData[filter].includes('all') ? [...items, 'all'] : [];
    this.filterData = {...this.filterData, ...filterData};

    if(filter === 'lines') { this.fetchStations() };
  };

  fetchStations() {
    if(this.filterData.lines.length === 0 || this.filterData.lines.includes('all')) {
      this.alarmsAndEscalationsService.fetchStations().subscribe(res => this.stations = res);
    } else {
      this.alarmsAndEscalationsService.fetchLinesBySector({ lines: this.filterData.lines }).subscribe(res => this.stations = res);
    }
  }

  updateSelection(filter: string, callback?: () => {}) {
    const filterData = {};
    const data = {
      lines: this.lines,
      stations: this.stations,
      status: this.status,
      configuredAlarms: this.configuredAlarms,
      users: this.users,
      priorities: this.priorities,
    };

    for (const [key, value] of Object.entries(this.filterData)) {
      filterData[key] = value;
    }

    if(filterData[filter].length === data[filter].length) {
      filterData[filter] = filterData[filter].includes('all') ? filterData[filter] : [...filterData[filter], 'all'];
    } 
    if (filterData[filter].filter(i => i !== 'all').length !== data[filter].length) {
      filterData[filter] = filterData[filter].filter(i => i !== 'all');
    }
    this.filterData = {...this.filterData, ...filterData};
    
    if(filter === 'lines') { this.fetchStations() };
  }

  getTranslatedAlarm(alarm): string {
    return this.commonService.getTranslatedName('name', alarm?.configuredAlarm?.translationJson || null);
  }

  getCustomFieldName(customField): string {
    return this.commonService.getTranslatedName(customField?.name, customField?.translationJson || null);
  }

  isImageData(data) {
    return data?.type === 'File';
  }

  getImageSrc(data, alarmId: number) {
    return `${this.apiUrl}/api/alarm-file/get-content/${alarmId}/${data?.uniqueId}?authentication_token=${localStorage.getItem("token")}`;          
  }

  onResizeColumn(event: any, column: string) {
    const index = _.findIndex(this.displayedColumns, (item) => item === column);
    this.resizeTableDirective.onResizeColumn(event, index);
  }
}
