<div mat-dialog-content>
  <ng-container [ngSwitch]="data.file.type">
    <ng-container *ngSwitchCase="fileTypeEnum.IMAGE">
      <img [src]="data.file.src">
    </ng-container>
    <ng-container *ngSwitchCase="fileTypeEnum.VIDEO">
      <video [src]="data.file.src" controls #recordedVideo autoplay></video>
    </ng-container>
    <ng-container *ngSwitchCase="fileTypeEnum.PDF">
      <ngx-extended-pdf-viewer 
        [src]="data.file.src" 
        [showToolbar]="false" 
        [showSidebarButton]="false" 
        [showFindButton]="false" 
        [showPagingButtons]="false" 
        [showDrawEditor]="false">
      </ngx-extended-pdf-viewer>
    </ng-container>
    <ng-container *ngSwitchDefault>
      <p style="text-align: center;">{{ 'FILE.PREVIEW_NOT_AVAILABLE' | translate }}</p>
    </ng-container>
  </ng-container>
</div>
<div mat-dialog-actions class="footer">
  <div class="footer" fxLayout="row" fxLayoutAlign="end center" fxLayoutGap="8px">
    <a mat-button color="secondary" [href]="mediaUrl" [download]="filename">
      {{ 'COMMON.DOWNLOAD' | translate }}
    </a>
    <button color="primary" mat-button (click)="close()" type="button">{{ 'COMMON.CANCEL' | translate }}
    </button>
  </div>
</div>