import * as _ from 'lodash';
import { Component, OnDestroy, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { BreadcrumbComponent } from '../../shared/components/breadcrumb.component';
import { ActivatedRoute, Router } from '@angular/router';
import { BackupService } from './services/backup.service';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import * as moment from 'moment';
import { SocketService } from '../../_services/socket.service';
import { environment } from '../../../environments/environment';
import { BackupEventInterface } from '../../../../common/backups/interfaces/backup-event.interface';
import { Socket } from '../../../../common/socket/models/socket.model';
import { InfoboxService } from '../../../../common/shared/services/infobox.service';
import { EventRepeatEnum } from '../../../../common/schedule/enums/event-repeat.enum';
import { MAX_BACKUP_EVENT_LIST_NUMBER } from '../../../../common/backups/constants/backups.constants';
import { BackupInterface } from '../../../../common/backups/interfaces/backup.interface';
import { SocketMessageEnum } from '../../../../common/socket/enums/socket-message.enum';
import { SocketChannel } from '../../../../common/socket/utils/socket-channel';
import { ResizeTableDirective } from '../../../app/shared/directives/resizeTable.directive';
import { MatLegacyTableDataSource as MatTableDataSource } from '@angular/material/legacy-table';

@Component({
  selector: 'app-backup',
  templateUrl: './backup.component.html',
  styleUrls: ['./backup.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class BackupComponent  extends BreadcrumbComponent implements OnInit, OnDestroy {
  @ViewChild(ResizeTableDirective) resizeTableDirective: ResizeTableDirective;

  backupEvents: BackupEventInterface[] = [];
  form: UntypedFormGroup;
  loading = false;
  backupCreated = false;
  showForm = false;
  disabledInstantBackup = false;
  private socket: Socket;
  private socketSubs: string[] = [];
  public displayedColumns: string[] = ['start', 'end', 'fileName', 'isFinished', 'actions'];
  public columns: any[] = [
      { field: 'start', width: 20, },
      { field: 'end', width: 20, },
      { field: 'fileName', width: 20, },
      { field: 'isFinished', width: 20, },
      { field: 'actions', width: 20, },
    ];
  dataSource = new MatTableDataSource<BackupEventInterface[]>([]);

  constructor(
      private backupService: BackupService,
      public route: ActivatedRoute,
      public router: Router,
      private infoboxService: InfoboxService,
      private socketService: SocketService,
  ) {
    super(route, router);
  }

  ngOnInit(): void {
    this.initForm();
    this.initBreadcrumbs();
    this.loadBackup();
    this.loadBackupEvents();
    this.initSockets();
  }

  ngOnDestroy() {
    this.leaveSockets();
  }

  initForm() {
    this.form = new UntypedFormGroup({
      id: new UntypedFormControl(undefined),
      name: new UntypedFormControl(null),
      period: new UntypedFormControl({
        repeatEvery: {
          count: 1,
          repeatEnum: EventRepeatEnum.EVERY_DAY
        },
        repeatOn: {
          daysOfWeek: [moment().isoWeekday()]
        },
        ends: {
          on: null,
          after: null
        },
        start: null
      }),
      start: new UntypedFormControl(null),
      end: new UntypedFormControl(null),
    });
  }

  makeInstantBackup() {
    this.disabledInstantBackup = true;
    this.backupService.createInstantBackup().subscribe((result) => {
      this.backupEvents.unshift(result);
      if (this.backupEvents.length > MAX_BACKUP_EVENT_LIST_NUMBER) {
        this.backupEvents.pop();
      }
      this.infoboxService.success('SPA.BACKUP.BACKUP_IN_PROGRESS');
    });
  }

  saveBackupData() {
    if (!this.form.valid) {
      return;
    }
    this.backupService.saveBackupData(this.form).subscribe((result) => {
      this.backupCreated = !!result.period;
      this.patchForm(result);
      this.infoboxService.success('SPA.BACKUP.BACKUP_SCHEDULE_CREATED');
    });
  }

  stop() {
    this.form.patchValue({period: null});
    this.backupService.saveBackupData(this.form).subscribe((result) => {
      this.infoboxService.success('SPA.BACKUP.BACKUP_SCHEDULE_STOPPED');
      this.backupCreated = !!result.period;
      this.showForm = this.backupCreated;
      this.patchForm(result);
    });
  }

  loadBackup() {
    this.backupService.getBackupData().subscribe((result) => {
      if (result && result.id) {
        this.backupCreated = !!result.period;
        this.showForm = this.backupCreated;

        this.patchForm(result);
      }
    });
  }

  loadBackupEvents() {
    this.backupService.getBackupEvents().subscribe((result) => {
      this.backupEvents = result;
      this.dataSource = new MatTableDataSource<any>(result);
      this.disabledInstantBackup = !!this.backupEvents.find(e => e.isActive);
    });
  }

  download(event: BackupEventInterface) {
    this.loading = true;

    this.backupService.checkFileExists(event.id).subscribe(exists => {
      this.loading = false;

      if (exists) {
        window.location.href = environment.apiUrl + `/api/backup/download/${event.id}`

        return;
      }

      this.infoboxService.error('API.FILE.NOT_EXISTS');

      this.loadBackupEvents();
    });
  }

  getFormButtonText(): string {
    return this.backupCreated
      ? 'SPA.BACKUP.UPDATE_BACKUP_SCHEDULER'
      : 'SPA.BACKUP.CREATE_BACKUP_SCHEDULER';
  }

  private patchForm(result: BackupInterface) {
    const defaultNewPeriod = {
      repeatEvery: {
        count: 1,
        repeatEnum: EventRepeatEnum.EVERY_DAY
      },
      repeatOn: {
        daysOfWeek: [moment().isoWeekday()]
      },
      ends: {
        on: null,
        after: null
      },
      start: null
    }

    const period = !!result.period
      ? result.period
      : this.form.get('period').value 
        ? this.form.get('period').value
        : defaultNewPeriod;

    period.start = period.start ? moment(period.start as string) : null;

    const newValue = {
      ...result,
      ...{
        period: period
      }
    }

    this.form.patchValue(newValue);
  }

  private initSockets() {
    this.socket = this.socketService.getSocket();
    this.socket.join(SocketChannel.BLOCK);
    this.socketSubs.push(this.socket.on(SocketMessageEnum.BACKUP_STARTED, _ => {
      this.loadBackupEvents();
    }));

    this.socketSubs.push(this.socket.on(SocketMessageEnum.BACKUP_FINISHED, _ => {
      this.loadBackupEvents();
    }));
  }

  private leaveSockets() {
    if (this.socket) {
      this.socketSubs.forEach(s => this.socket.off(s));
      this.socket.leave(SocketChannel.BLOCK);
    }
  }

  onResizeColumn(event: any, column: string) {
    const index = _.findIndex(this.displayedColumns, (item) => item === column);
    this.resizeTableDirective.onResizeColumn(event, index);
  }
}
