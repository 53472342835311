import * as _ from 'lodash';
import * as moment from 'moment';
import { Moment } from 'moment';
import * as momentTimezone from 'moment-timezone';
import {Component, OnDestroy, OnInit, ViewChild, Renderer2, ElementRef} from '@angular/core';
import { catchError, of, Subject, Subscription, tap, timer, forkJoin } from 'rxjs';
import {AEGroupInterface} from '../../groups/interfaces/a-e-group.interface';
import {MatLegacyPaginator as MatPaginator} from '@angular/material/legacy-paginator';
import {MatSort} from '@angular/material/sort';
import {MatLegacyTableDataSource as MatTableDataSource} from '@angular/material/legacy-table';
import { AlarmsAndEscalationsService } from '../../alarms-and-escalations.service';
import { SaveFilterService } from '../../../../../common/shared/services/save-filter.service';
import { historicAlarmFilterKey } from '../../../../../common/filters/constants/filter-key.constants';
import { TableColumnVisibilityService } from '../../../../../common/user-table-settings/services/table-column-visibility.service';
import { UserTableTypeEnum } from '../../../../../common/user-table-settings/enums/user-table-type.enum';
import { UserTablePlaceEnum } from '../../../../../common/user-table-settings/enums/user-table-place.enum';
import { CommonService } from '../../../../../common/shared/services/common.service';
import {
    ENDED_ALARMS_FILTERS_MAX_TIME_DIFFERENCE,
    ENDED_ALARMS_FILTERS_TIME_DIFFERENCE_UNITS
} from '../../../../../common/alarms-and-escalations/constants/filters.constants';
import { InfoboxService } from '../../../../../common/shared/services/infobox.service';
import { UserService } from '../../../user-management/users/user.service';
import { HistoricAlarmFiltersInterface, HistoricAlarmParamsInterface } from '../../../../../common/filters/interfaces/historic-alarm-filters.interface';
import { DATE_TIME_FORMAT } from '../../../../../common/shared/constants/time.constants';
import { ResizeTableDirective } from '../../../shared/directives/resizeTable.directive';

@Component({
    selector: 'app-historic-alarms',
    templateUrl: './historic-alarms.component.html',
    styleUrls: ['./historic-alarms.component.scss']
})
export class HistoricAlarmsComponent implements OnInit, OnDestroy {
    @ViewChild(ResizeTableDirective) resizeTableDirective: ResizeTableDirective;

    groups: AEGroupInterface[] = [];
    displayedColumns: string[] = [];
    columnsData = [
        {
          name: "alarmId",
          id: "Alarm id",
          isAlwaysVisible: true,
          isVisible: true,
        },
        {
          name: "lineName",
          id: "Production Sector",
          isAlwaysVisible: false,
          isVisible: true,
        },
        {
          name: "stationName",
          id: "Station",
          isAlwaysVisible: false,
          isVisible: true,
        },
        {
          name: "configuredAlarmName",
          id: "Alarm Type",
          isAlwaysVisible: false,
          isVisible: true,
        },
        {
          name: "reason",
          id: "Reason",
          isAlwaysVisible: false,
          isVisible: true,
        },
        {
          name: "creator",
          id: "Creator",
          isAlwaysVisible: false,
          isVisible: true,
        },
        {
          name: "resolvedBy",
          id: "Resolved By",
          isAlwaysVisible: false,
          isVisible: true,
        },
        {
          name: "occurred",
          id: "Start",
          isAlwaysVisible: false,
          isVisible: true,
        },
        {
          name: "resolved",
          id: "End",
          isAlwaysVisible: false,
          isVisible: true,
        },
        {
            name: "duration",
            id: "Duration",
            isAlwaysVisible: false,
            isVisible: true,
          },
          {
            name: "actions",
            id: "Actions",
            isAlwaysVisible: true,
            isVisible: true,
          },
      ];
    dataSource = new MatTableDataSource<any>([]);
    timezone = momentTimezone.tz.guess();
    lines = [];
    stations = [];
    configuredAlarms = [];
    disorders = [];
    creators = [];
    resolvers = [];
    filterData: HistoricAlarmFiltersInterface = {
        lines: [],
        stations: [],
        configuredAlarms: [],
        disorders: [],
        creators: [],
        resolvers: [],
        startRange: '',
        startFrom: '',
        startTo: '',
        endRange: '',
        endFrom: '',
        endTo: '',
    };
    @ViewChild(MatPaginator) paginator: MatPaginator;
    @ViewChild(MatSort) sort: MatSort;
    showFilters = false;
    filterToSend: HistoricAlarmFiltersInterface = {
        lines: [],
        stations: [],
        configuredAlarms: [],
        disorders: [],
        creators: [],
        resolvers: [],
        startRange: '',
        startFrom: '',
        startTo: '',
        endRange: '',
        endFrom: '',
        endTo: '',
    };
    isStationIdInFilter: boolean = false;
    data = [];
    isMobileScreen: boolean;
    private pollingSubscription: Subscription;
    isFetchingResolvedAlarms: boolean = false;
    private dateRangeCorrect = true;
    totalData: number;    
    columns: any[] = [
        { field: 'alarmId', width: 20, },
        { field: 'lineName', width: 20, },
        { field: 'stationName', width: 20, },
        { field: 'configuredAlarmName', width: 20, },
        { field: 'reason', width: 20, },
        { field: 'creator', width: 20, },
        { field: 'resolvedBy', width: 20, },
        { field: 'duration', width: 20, },
        { field: 'actions', width: 20, },
    ];

    constructor(private alarmsAndEscalationsService : AlarmsAndEscalationsService,
        private saveFilterService: SaveFilterService,private tableColumnVisibilityService: TableColumnVisibilityService, 
        private commonService: CommonService,
        private renderer: Renderer2, 
        private infoboxService: InfoboxService,
        private userService: UserService,
        private el: ElementRef) {

    }

    ngOnInit(): void {
        this.fetchFiltersData();
        this.fetchUserFilters();

        this.getColumns();

        this.renderer.listen('window', 'resize', (event) => {
            this.getWindowWidth();
          });
      
          this.getWindowWidth();

    }

    ngAfterViewInit() {
        if(this.paginator) {
            this.paginator.page.subscribe(paginator => this.loadList());
        }
        if(this.sort) {
            this.sort.sortChange.subscribe(sort => this.loadList());
        }
    }

    fetchFiltersData() {
        const observables = [
            this.alarmsAndEscalationsService.fetchLines(),
            this.alarmsAndEscalationsService.fetchStations(),
            this.alarmsAndEscalationsService.getAllConfiguredAlarm(),
            this.alarmsAndEscalationsService.getAllReasons(),
            this.userService.getAllUsers(),
        ];
        forkJoin(observables).subscribe(([lines, stations, configuredAlarms, disorders, users]) => {
            this.lines = lines;
            this.stations = stations;
            this.configuredAlarms = configuredAlarms;
            this.disorders = disorders.map(item => {
                item.displayReasonName = this.getTranslatedName('name', JSON.parse(item.reasonTranslationJson));
                return item;
            });
            this.creators = users;
            this.resolvers = users;
        });
    }

    fetchUserFilters() {
        this.saveFilterService.getFiltersUsingKey(historicAlarmFilterKey).subscribe(filters => {
            this.filterData = {...filters};
            this.filterToSend = {...filters};
            this.loadList();
        });
    }

    getWindowWidth() {
        this.isMobileScreen = this.renderer.parentNode(this.el.nativeElement).ownerDocument.defaultView.innerWidth <= 768
      }
    
    
    loadList = () => {
        // Return if API calling is happening
        if (this.isFetchingResolvedAlarms) return;
      
        this.isFetchingResolvedAlarms = true;
      
        const params: HistoricAlarmParamsInterface = {
            sortCol: this.sort?.active || 'occurred',
            sortDir: this.sort?.direction || 'DESC',
            pageNo: this.paginator?.pageIndex || 0,
            pageSize: this.paginator?.pageSize || 10
        };
      
        this.alarmsAndEscalationsService
            .adminGetAllResolvedAlarmsWithFilter(params, this.filterToSend)
            .pipe(
                tap((response) => {
                    this.isFetchingResolvedAlarms = false;
            
                    this.dataSource = new MatTableDataSource<any>(response.results);
                    this.data = response.results;
                    this.totalData = response.total;
            
                    this.startPolling();
                }),
                // Handle errors with catchError
                catchError((error) => {
                    console.error('Error fetching resolved alarms:', error);
            
                    this.isFetchingResolvedAlarms = false;
                    this.startPolling();
            
                    // Return an empty observable to ensure the stream continues
                    return of([]);
                })
            )
            .subscribe();
    };

    // Start polling after getting the response
    startPolling() {
        this.stopPolling(); 

        // Set up a timer that emits every 5 seconds
        this.pollingSubscription = timer(5000).subscribe(() => {
            this.loadList();
        });
    }

    // Stop polling and unsubscribe 
    stopPolling() {
        if (this.pollingSubscription) {
            this.pollingSubscription.unsubscribe();
            this.pollingSubscription = null; 
        }
    }

    getColumns() {
        this.tableColumnVisibilityService.loadTableMetaData(UserTableTypeEnum.HISTORIC_ALARMS,UserTablePlaceEnum.SPA).subscribe((result:any)=>{
            if(result?.length > 0){
                this.columnsData = result;
            }
            this.setColumns();
        })
      }

    setColumns(){
        this.displayedColumns = [];
        this.columnsData.forEach((element) => {
            if (element.isVisible) {
              this.displayedColumns.push(element.name);
            }
        });
    }
    
    columnsChanged() {    
        let data = {
            tableTypeEnum: UserTableTypeEnum.HISTORIC_ALARMS,
            placeEnum: UserTablePlaceEnum.SPA,
            columns: this.columnsData
        }
        this.tableColumnVisibilityService.saveTableMetaData(data).subscribe((result: any)=>{
            this.columnsData = result;
            this.setColumns();
        })
    }

    accept(id) {

    }

    forward(id) {

    }

    changeFilters() {
        for (const [key, value] of Object.entries(this.filterData)) {
            this.filterToSend[key] = _.isArray(value) ?  value.filter(i => i !== 'all') : value;
          }
        this.saveFilterService.createObject(historicAlarmFilterKey, this.filterToSend);
        this.loadList();
    }

    resetFilters() {
        const data: HistoricAlarmFiltersInterface = {
            lines: [],
            stations: [],
            configuredAlarms: [],
            disorders: [],
            creators: [],
            resolvers: [],
            startRange: '',
            startFrom: '',
            startTo: '',
            endRange: '',
            endFrom: '',
            endTo: '',
        }
        this.filterToSend = { ...data };
        this.filterData = { ...data };
        this.saveFilterService.createObject(historicAlarmFilterKey, this.filterToSend);
        this.loadList();   
    }

    ngOnDestroy(){
        this.stopPolling();
    }

    getTranslatedName(name, translationObj): string {
        return this.commonService.getTranslatedName(name, translationObj);
    }

    getTranslatedAlarm(alarm): string {
        return this.commonService.getTranslatedName('name', alarm?.configuredAlarm?.translationJson || null);
    }

    exporToCsv() {
        const params: HistoricAlarmParamsInterface = {
            sortCol: this.sort?.active || 'occurred',
            sortDir: this.sort?.direction || 'DESC',
        };
        this.alarmsAndEscalationsService
            .exportResolvedAlarmsWithFilterToCsv(params)
            .subscribe();
    }

    rangeChange(rangeName: string, fieldPrefix: string) {
        let begin: Moment, end: Moment;
        this.filterData[`${fieldPrefix}Range`] = rangeName;
        switch (rangeName) {
            case 'YESTERDAY': {
                begin = moment().subtract(1, 'days').startOf('day');
                end = moment().subtract(1, 'days').endOf('day');
                break;
            }
            case 'CURRENT_WEEK': {
                begin = moment().startOf('isoWeek');
                end = moment().endOf('isoWeek');
                break;
            }
            case 'LAST_WEEK': {
                begin = moment().subtract(1, 'weeks').startOf('isoWeek');
                end = moment().subtract(1, 'weeks').endOf('isoWeek');
                break;
            }
            case 'CURRENT_MONTH': {
                begin = moment().startOf('month');
                end = moment().endOf('month');
                break;
            }
            case 'LAST_MONTH': {
                begin = moment().subtract(1, 'months').startOf('month');
                end = moment().subtract(1, 'months').endOf('month');
                break;
            }
            default: {
                begin = moment().startOf('day');
                end = moment().endOf('day');
                break;
            }
        }

        this.checkDates(begin, end);

        this.filterData[`${fieldPrefix}From`] = moment(begin).format(DATE_TIME_FORMAT);
        this.filterData[`${fieldPrefix}To`] = moment(end).format(DATE_TIME_FORMAT);
    }

    changeBeginDate(begin: Moment, fieldPrefix: string) {
        this.filterData[`${fieldPrefix}From`] = moment(begin).format(DATE_TIME_FORMAT);
        const end = moment(this.filterData[`${fieldPrefix}To`]);

        this.checkDates(begin, end);
    }

    changeEndDate(end: Moment, fieldPrefix: string) {
        this.filterData[`${fieldPrefix}To`] = moment(end).format(DATE_TIME_FORMAT);
        const begin = moment(this.filterData[`${fieldPrefix}From`]);

        this.checkDates(begin, end);
    }

    private checkDates(begin: Moment, end: Moment) {
        if (!(end.diff(begin, ENDED_ALARMS_FILTERS_TIME_DIFFERENCE_UNITS) > ENDED_ALARMS_FILTERS_MAX_TIME_DIFFERENCE)) {
            this.dateRangeCorrect = true;
            return;
        }

        this.dateRangeCorrect = false;

        this.infoboxService.error('SPA.CHARTS.TIME_DIFFERENCE_TOO_LARGE', {
            maxTimeDifference: ENDED_ALARMS_FILTERS_MAX_TIME_DIFFERENCE
        });
    }
    
    fetchStations() {
        if(this.filterData.lines.length === 0 || this.filterData.lines.includes('all')) {
          this.alarmsAndEscalationsService.fetchStations().subscribe(res => this.stations = res);
        } else {
          this.alarmsAndEscalationsService.fetchLinesBySector({ lines: this.filterData.lines }).subscribe(res => this.stations = res);
        }
    }

    toggleSelection(filter: string) {
        const filterData = {};
        const data = {
          lines: this.lines,
          stations: this.stations,
          configuredAlarms: this.configuredAlarms, 
          disorders: this.disorders,
          creators: this.creators,
          resolvers: this.resolvers,
        };
        const items = data[filter].map(item => {
            switch(filter) {
                case 'disorders':
                    return this.getTranslatedName('name', JSON.parse(item.reasonTranslationJson));
                default:
                    return item.id;
            }
        });
    
        for (const [key, value] of Object.entries(this.filterData)) {
          filterData[key] = value;
        }
        filterData[filter] = filterData[filter].includes('all') ? [...items, 'all'] : [];
        this.filterData = {...this.filterData, ...filterData};
    
        if(filter === 'lines') { this.fetchStations() };
    }

    updateSelection(filter: string) {
        const filterData = {};
        const data = {
            lines: this.lines,
            stations: this.stations,
            configuredAlarms: this.configuredAlarms, 
            disorders: this.disorders,
            creators: this.creators,
            resolvers: this.resolvers,
        };
    
        for (const [key, value] of Object.entries(this.filterData)) {
          filterData[key] = value;
        }
    
        if(filterData[filter].length === data[filter].length) {
          filterData[filter] = filterData[filter].includes('all') ? filterData[filter] : [...filterData[filter], 'all'];
        } 
        if (filterData[filter].filter(i => i !== 'all').length !== data[filter].length) {
          filterData[filter] = filterData[filter].filter(i => i !== 'all');
        }
        this.filterData = {...this.filterData, ...filterData};
        
        if(filter === 'lines') { this.fetchStations() };
    }

    onResizeColumn(event: any, column: string) {
        const index = _.findIndex(this.displayedColumns, (item) => item === column);
        this.resizeTableDirective.onResizeColumn(event, index);
    }
}
