import { FileTypeEnum } from '../constants/mime-types.constants';

export class FiltyTypeUtil {
  static getFileType(mimeType: string) {
    const mimeTypeMap = {
      'image/': FileTypeEnum.IMAGE,
      'video/': FileTypeEnum.VIDEO,
      'audio/': FileTypeEnum.AUDIO,
      'application/pdf': FileTypeEnum.PDF,
      'application/zip': FileTypeEnum.APK,
      'text/plain': FileTypeEnum.IO_STRING_WORKSTEP_WIDGET,
      'text/csv': FileTypeEnum.CSV,
      'application/vnd.ms-excel': FileTypeEnum.EXCEL,
      'application/vnd-xls': FileTypeEnum.EXCEL,
      'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': FileTypeEnum.EXCEL,
      'application/excel': FileTypeEnum.EXCEL,
      'application/x-excel': FileTypeEnum.EXCEL,
      'application/x-msexcel': FileTypeEnum.EXCEL,
      'application/vnd.oasis.opendocument.spreadsheet': FileTypeEnum.EXCEL,
      'application/json': FileTypeEnum.JSON,
      'application/ld+json': FileTypeEnum.JSON,
    };
    
    for(const key in mimeTypeMap) {
      if(mimeType.startsWith(key)) {
        return mimeTypeMap[key];
      }
    }
    return FileTypeEnum.OTHER;
  }
}