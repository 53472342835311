import * as _ from 'lodash';
import {Component, ElementRef, OnInit, ViewChild} from '@angular/core';
import {UserService} from './user.service';
import {ModalsService} from '../../../../common/shared/services/modals.service';
import { UserInterface } from '../../../../common/auth/interfaces/user.interface';
import { AuthService } from '../../auth/auth.service';
import { MatLegacyTableDataSource as MatTableDataSource } from '@angular/material/legacy-table';
import { ResizeTableDirective } from '../../../app/shared/directives/resizeTable.directive';

@Component({
    styleUrls: ['users.component.scss'],
    templateUrl: 'users.component.html'
})
export class UsersComponent implements OnInit {
    public searchPhrase = '';
    loading = false;
    @ViewChild('table') table: ElementRef;
    @ViewChild(ResizeTableDirective) resizeTableDirective: ResizeTableDirective;

    private currentUser: UserInterface;
    public displayedColumns: string[] = ['role', 'username', 'firstname', 'name', 'email', 'coin', 'actions'];
    public columns: any[] = [
        { field: 'role', width: 20, },
        { field: 'username', width: 20, },
        { field: 'firstname', width: 20, },
        { field: 'name', width: 20, },
        { field: 'email', width: 20, },
        { field: 'coin', width: 20, },
        { field: 'actions', width: 20, },
      ];
    dataSource = new MatTableDataSource<UserInterface[]>([]);

    constructor(
        private userService: UserService,
        private modalsService: ModalsService,
        private authService: AuthService,
    ) {}

    ngOnInit() {
        this.authService.currentUser$.subscribe(user => {
            this.currentUser = user;
        });
        this.loading = true;
        this.userService.getUsers().subscribe(users => {
            this.dataSource = new MatTableDataSource<any>(users);
            this.loading = false;
        }, () => {
            this.loading = false;
        });
    }

    disableDelete(user: UserInterface): boolean {
        return user.username === 'admin' || this.currentUser.id === user.id;
    }

    removeUser(user: UserInterface) {
        if (user.username === 'admin') {
            return;
        }
        this.modalsService.confirm('SPA.USERS.RemoveConfirmation').subscribe((result) => {
            if (result) {
                this.userService.deleteUser(user.id).subscribe(() => {
                    this.userService.getUsers().subscribe(users => {
                        this.dataSource = new MatTableDataSource<any>(users);
                    });
                });
            }
        });
    }

    search() {
        const field = this.searchPhrase.toLowerCase();
        const row = this.table.nativeElement.querySelectorAll('.mat-row');

        for (const singleRow of row) {
            if (singleRow) {

                const td = singleRow.querySelectorAll('td');
                for (const item of td) {
                    if (item) {
                        if (item.textContent.toString().toLowerCase().indexOf(field) >= 0) {
                            singleRow.hidden = false;
                            break;
                        } else {
                            singleRow.hidden = true;
                        }
                    }
                }
            }
        }
    }

    onResizeColumn(event: any, column: string) {
        const index = _.findIndex(this.displayedColumns, (item) => item === column);
        this.resizeTableDirective.onResizeColumn(event, index);
    }
}