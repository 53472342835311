<mat-card>
  <page-header
    titleTranslate="SPA.ALARMS_ESCALATIONS.DASHBOARD.CURRENT_ALARMS.Header"
  ></page-header>
  <div class="header" (click)="showFilters = !showFilters">
    <page-header titleTranslate="COMMON.FILTERS"></page-header>
    <mat-icon>{{ showFilters ? "expand_more" : "chevron_right" }}</mat-icon>
  </div>
  <div class="column-setting" *ngIf="isMobileScreen">
    <app-select-columns
      [columns]="columnsData"
      icon="settings"
      (columnsChanged)="columnsChanged()"
    ></app-select-columns>
  </div>
  <ng-container *ngIf="showFilters">
    <div
      fxLayout="row"
      fxLayout.lt-sm="column"
      fxLayoutGap="16px"
      fxLayoutGap.lt-sm="0"
      style="align-items: baseline"
    >
      <mat-form-field
        fxFlex="auto"
        appearance="outline"
        class="vex-flex-form-field"
        color="primary"
      >
        <mat-label
          >{{
            "SPA.ALARMS_ESCALATIONS.DASHBOARD.HISTORIC_ALARMS.PRODUCTION_SECTOR"
              | translate
          }}
        </mat-label>
        <mat-select
          [(ngModel)]="filterData.lines"
          multiple
          >
          <mat-option value="all" (click)="toggleSelection('lines')">All</mat-option>
          <mat-option
            *ngFor="let line of lines"
            [value]="line.id"
            (click)="updateSelection('lines')"
            >{{ line.name }}</mat-option
          >
        </mat-select>
      </mat-form-field>

      <mat-form-field
        fxFlex="auto"
        appearance="outline"
        class="vex-flex-form-field"
        color="primary"
      >
        <mat-label>{{
          "SPA.ALARMS_ESCALATIONS.DASHBOARD.HISTORIC_ALARMS.STATION" | translate
        }}</mat-label>
        <mat-select [(ngModel)]="filterData.stations" multiple>
          <mat-option value="all" (click)="toggleSelection('stations')">All</mat-option>
          <mat-option *ngFor="let station of stations" [value]="station.id" (click)="updateSelection('stations')">{{
            station.name
          }}</mat-option>
        </mat-select>
      </mat-form-field>
      <mat-form-field
        fxFlex="auto"
        appearance="outline"
        class="vex-flex-form-field"
        color="primary"
      >
        <mat-label>{{
          "SPA.ALARMS_ESCALATIONS.DASHBOARD.HISTORIC_ALARMS.STATUS" | translate
        }}</mat-label>
        <mat-select [(ngModel)]="filterData.status" multiple>
          <mat-option value="all" (click)="toggleSelection('status')">All</mat-option>
          <mat-option *ngFor="let st of status" [value]="st" (click)="updateSelection('status')">
            {{ "ENUMS.ALARM_EVENT." + st | translate }}
          </mat-option>
        </mat-select>
      </mat-form-field>
    </div>
    <div fxLayout="row" fxLayout.lt-sm="column" fxLayoutGap="16px" fxLayoutGap.lt-sm="0" style="align-items: baseline">
      <!-- Alarm type Field -->
      <mat-form-field fxFlex="auto" appearance="outline" class="vex-flex-form-field" color="primary">
        <mat-label>{{
          "SPA.ALARMS_ESCALATIONS.DASHBOARD.CURRENT_ALARMS.ALARM_TYPE"
          | translate
          }}
        </mat-label>
        <mat-select [(ngModel)]="filterData.configuredAlarms" multiple>
          <mat-option value="all" (click)="toggleSelection('configuredAlarms')">All</mat-option>
          <mat-option *ngFor="let configuredAlarm of configuredAlarms" [value]="configuredAlarm.id" (click)="updateSelection('configuredAlarms')">{{ configuredAlarm.name }}</mat-option>
        </mat-select>
      </mat-form-field>
    
      <!-- Current User  -->
      <mat-form-field fxFlex="auto" appearance="outline" class="vex-flex-form-field" color="primary">
        <mat-label>{{
          "SPA.ALARMS_ESCALATIONS.DASHBOARD.CURRENT_ALARMS.CURRENT_USER"
          | translate
          }}
        </mat-label>
        <mat-select [(ngModel)]="filterData.users" multiple>
          <mat-option value="all" (click)="toggleSelection('users')">All</mat-option>
          <mat-option *ngFor="let user of users" [value]="user.id" (click)="updateSelection('users')">{{ user | formatUserName }}</mat-option>
        </mat-select>
      </mat-form-field>
    
      <!-- Priority  -->
      <mat-form-field fxFlex="auto" appearance="outline" class="vex-flex-form-field" color="primary">
        <mat-label>{{
          "SPA.ALARMS_ESCALATIONS.DASHBOARD.CURRENT_ALARMS.PRIORITY"
          | translate
          }}
        </mat-label>
        <mat-select [(ngModel)]="filterData.priorities" multiple>
          <mat-option value="all" (click)="toggleSelection('priorities')">All</mat-option>
          <mat-option *ngFor="let priority of priorities" [value]="priority.id" (click)="updateSelection('priorities')">{{ priority.label | translate }}
          </mat-option>
        </mat-select>
      </mat-form-field>
    </div>

    <!-- Filter buttons -->
    <div class="mb-2" fxLayout="row" fxLayoutGap="8px" fxLayoutAlign="end">
      <button class="submit-btn" (click)="changeFilters()" color="primary" mat-raised-button>
        {{
        "SPA.ALARMS_ESCALATIONS.DASHBOARD.HISTORIC_ALARMS.UPDATE"
        | translate
        }}
      </button>
      <button class="submit-btn" (click)="resetFilters()" color="warn" mat-raised-button>
        {{
        "SPA.ALARMS_ESCALATIONS.DASHBOARD.HISTORIC_ALARMS.RESET" | translate
        }}
      </button>
    </div>
  </ng-container>
  <div style="display: flex">
    <div style="width: 95%" *ngIf="!isMobileScreen">
      <div style="overflow-x: auto">
        <table 
          table 
          matSort
          mat-table 
          matSortDisableClear
          [dataSource]="dataSource"
         #currentAlarmTable resizeTable [columns]="columns" [displayedColumns]="displayedColumns">
          <ng-container matColumnDef="colorStatus">
            <th mat-header-cell *matHeaderCellDef>
              {{
                "SPA.ALARMS_ESCALATIONS.DASHBOARD.CURRENT_ALARMS.STATUS"
                  | translate
              }}
              <span class="resize-handle" (mousedown)="onResizeColumn($event, 'colorStatus')"></span>
            </th>
            <td mat-cell *matCellDef="let element">
              <span
                class="alarms-status"
                [ngStyle]="{
                  'background-color': element?.configuredAlarm?.color
                }"
              >
              </span>
            </td>
          </ng-container>

          <ng-container matColumnDef="status">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>
              {{
                "SPA.ALARMS_ESCALATIONS.DASHBOARD.CURRENT_ALARMS.STATUS_TEXT"
                  | translate
              }}
              <span class="resize-handle" (mousedown)="onResizeColumn($event, 'status')"></span>
            </th>
            <td mat-cell *matCellDef="let element">
              <a color="primary" [routerLink]="['../', element?.id]">{{
                "ENUMS.ALARM_EVENT." + element.status | translate
              }}</a>
            </td>
          </ng-container>

          <ng-container matColumnDef="id">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>
              {{
                "SPA.ALARMS_ESCALATIONS.DASHBOARD.CURRENT_ALARMS.ALARM_ID"
                  | translate
              }}
              <span class="resize-handle" (mousedown)="onResizeColumn($event, 'id')"></span>
            </th>
            <td mat-cell *matCellDef="let element">
              <a color="primary" [routerLink]="['../', element?.id]">{{
                element?.id
              }}</a>
            </td>
          </ng-container>

          <ng-container matColumnDef="lineName">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>
              {{
                "SPA.ALARMS_ESCALATIONS.DASHBOARD.CURRENT_ALARMS.PRODUCTION_SECTOR"
                  | translate
              }}
              <span class="resize-handle" (mousedown)="onResizeColumn($event, 'lineName')"></span>
            </th>
            <td mat-cell *matCellDef="let element">
              <a color="primary" [routerLink]="['../', element?.id]">{{
                element.lineName
              }}</a>
            </td>
          </ng-container>

          <ng-container matColumnDef="stationName">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>
              {{
                "SPA.ALARMS_ESCALATIONS.DASHBOARD.CURRENT_ALARMS.STATION"
                  | translate
              }}
              <span class="resize-handle" (mousedown)="onResizeColumn($event, 'stationName')"></span>
            </th>
            <td mat-cell *matCellDef="let element">
              <a color="primary" [routerLink]="['../', element?.id]">{{
                element.stationName
              }}</a>
            </td>
          </ng-container>

          <ng-container matColumnDef="configuredAlarmName">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>
              {{
                "SPA.ALARMS_ESCALATIONS.DASHBOARD.CURRENT_ALARMS.ALARM_TYPE"
                  | translate
              }}
              <span class="resize-handle" (mousedown)="onResizeColumn($event, 'configuredAlarmName')"></span>
            </th>
            <td mat-cell *matCellDef="let element">
              <a color="primary" [routerLink]="['../', element?.id]">{{
                getTranslatedAlarm(element)
              }}</a>
            </td>
          </ng-container>

          <ng-container matColumnDef="customFieldsData">
            <th mat-header-cell *matHeaderCellDef>
              {{
                "SPA.ALARMS_ESCALATIONS.DASHBOARD.CURRENT_ALARMS.CUSTOM_FIELD"
                  | translate
              }}
              <span class="resize-handle" (mousedown)="onResizeColumn($event, 'customFieldsData')"></span>
            </th>
            <td mat-cell *matCellDef="let element">
              <a color="primary" [routerLink]="['../', element?.id]">
                <div *ngFor="let customField of element.customFieldsData">
                  <!-- String data -->
                  <ng-container *ngIf="!isImageData(customField)">
                    <span>
                      {{ getCustomFieldName(customField) }} - {{ customField.valueAsAString }}
                    </span>
                    <br />
                  </ng-container>
                  
                  <!-- Image Data -->
                  <div *ngIf="isImageData(customField)">
                    <span>
                      {{ getCustomFieldName(customField) }} -
                    </span>
                    <br />
                    <div class="alarm-details-details image-container">
                      <div class="image" *ngFor="let file of customField.value">
                        <img *ngIf="file?.fileType === 'image'" [src]="getImageSrc(file, element?.id)">
                        <div class="video-wrapper" *ngIf="file?.fileType === 'video'">
                          <video #videoPlayer [src]="getImageSrc(file, element?.id)"></video>
                          <i class="material-icons right play_arrow">play_arrow</i>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </a>
            </td>
          </ng-container>

          <ng-container matColumnDef="alarmGroupName">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>
              {{
                "SPA.ALARMS_ESCALATIONS.DASHBOARD.CURRENT_ALARMS.CURRENT_ALARM_GROUP"
                  | translate
              }}
              <span class="resize-handle" (mousedown)="onResizeColumn($event, 'alarmGroupName')"></span>
            </th>
            <td mat-cell *matCellDef="let element">
              <a color="primary" [routerLink]="['../', element?.id]">{{
                element.alarmGroupName
              }}</a>
            </td>
          </ng-container>

          <ng-container matColumnDef="created">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>
              {{
                "SPA.ALARMS_ESCALATIONS.DASHBOARD.CURRENT_ALARMS.START"
                  | translate
              }}
              <span class="resize-handle" (mousedown)="onResizeColumn($event, 'created')"></span>
            </th>
            <td mat-cell *matCellDef="let element">
              <a color="primary" [routerLink]="['../', element?.id]">{{
                element.created | customDate
              }}</a>
            </td>
          </ng-container>

          <ng-container matColumnDef="duration">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>
              {{
                "SPA.ALARMS_ESCALATIONS.DASHBOARD.CURRENT_ALARMS.DURATION"
                  | translate
              }}
              <span class="resize-handle" (mousedown)="onResizeColumn($event, 'duration')"></span>
            </th>
            <td mat-cell *matCellDef="let element">
              <a color="primary" [routerLink]="['../', element?.id]">{{
                calculateDuration(element.duration)
              }}</a>
            </td>
          </ng-container>

          <ng-container matColumnDef="currentUser">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>
              {{
                "SPA.ALARMS_ESCALATIONS.DASHBOARD.CURRENT_ALARMS.CURRENT_USER"
                  | translate
              }}
              <span class="resize-handle" (mousedown)="onResizeColumn($event, 'currentUser')"></span>
            </th>
            <td mat-cell *matCellDef="let element">
              <a color="primary" [routerLink]="['../', element?.id]">
                {{
                  element.alarmAcceptingUser
                    ? (element.alarmAcceptingUser | formatUserName)
                    : ("SPA.ALARMS_ESCALATIONS.ALARM_PENDING" | translate)
                }}
              </a>
            </td>
          </ng-container>

          <ng-container matColumnDef="actions">
            <th mat-header-cell *matHeaderCellDef>
              <span class="resize-handle" (mousedown)="onResizeColumn($event, 'actions')"></span>
            </th>
            <td mat-cell *matCellDef="let element">
              <div
                fxLayout="row"
                fxLayout.lt-sm="column"
                fxLayoutGap="16px"
                fxLayoutGap.lt-sm="0"
                fxLayoutAlign="end"
              >
                <button
                  color="primary"
                  mat-raised-button
                  type="button"
                  *ngIf="element.status != 1"
                  (click)="accept(element)"
                >
                  <span>{{
                    "SPA.ALARMS_ESCALATIONS.DASHBOARD.CURRENT_ALARMS.ACCEPT"
                      | translate
                  }}</span>
                </button>
                <button
                  color="primary"
                  mat-raised-button
                  type="button"
                  *ngIf="
                    element.status == 1 &&
                    element.alarmAcceptingUserId === currentUser.id
                  "
                  (click)="unSubscribeAlarm(element)"
                >
                  <span>{{
                    "SPA.ALARMS_ESCALATIONS.DASHBOARD.CURRENT_ALARMS.UNSUBSCRIBE"
                      | translate
                  }}</span>
                </button>
                <button
                  color="warn"
                  mat-raised-button
                  type="button"
                  (click)="forward(element)"
                >
                  <span>{{
                    "SPA.ALARMS_ESCALATIONS.DASHBOARD.CURRENT_ALARMS.FORWARD"
                      | translate
                  }}</span>
                </button>
                <ng-container
                  *ngIf="
                    element.showEndAlarmButtonBasedOnStationMappingConfiguration
                  "
                >
                  <button
                    color="primary"
                    mat-raised-button
                    type="button"
                    *apUserAccess="permissions"
                    (click)="endAlarm(element)"
                  >
                    <span>{{
                      "SPA.ALARMS_ESCALATIONS.DASHBOARD.CURRENT_ALARMS.END"
                        | translate
                    }}</span>
                  </button>
                </ng-container>
              </div>
            </td>
          </ng-container>
          <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
          <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
        </table>
      </div>
      <mat-paginator 
        [length]="totalData" 
        [pageSizeOptions]="[5, 10, 20, 50]" showFirstLastButtons>
      </mat-paginator>
    </div>
    <div class="column-setting" *ngIf="!isMobileScreen">
      <app-select-columns
        [columns]="columnsData"
        icon="settings"
        (columnsChanged)="columnsChanged()"
        [translatable]="false"
      ></app-select-columns>
    </div>

    <div style="width: 100%" *ngIf="isMobileScreen">
      <mat-list role="list" *ngFor="let element of data">
        <mat-list-item
          role="listitem"
          *ngIf="displayedColumns.includes('colorStatus')"
        >
          <span matListItemTitle
            ><strong>
              {{
                "SPA.ALARMS_ESCALATIONS.DASHBOARD.CURRENT_ALARMS.STATUS"
                  | translate
              }}
            </strong></span
          >
          <span
            matListItemLine
            class="alarms-status"
            [ngStyle]="{
              'background-color': element?.configuredAlarm?.color
            }"
          >
          </span>
        </mat-list-item>
        <mat-list-item
          role="listitem"
          *ngIf="displayedColumns.includes('status')"
        >
          <span matListItemTitle
            ><strong>
              {{
                "SPA.ALARMS_ESCALATIONS.DASHBOARD.CURRENT_ALARMS.STATUS_TEXT"
                  | translate
              }}
            </strong></span
          >
          <span matListItemLine>
            <a color="primary" [routerLink]="['../', element?.id]">{{
              "ENUMS.ALARM_EVENT." + element.status | translate
            }}</a>
          </span>
        </mat-list-item>
        <mat-list-item
          role="listitem"
          *ngIf="displayedColumns.includes('id')"
        >
          <span matListItemTitle
            ><strong>
              {{
                "SPA.ALARMS_ESCALATIONS.DASHBOARD.CURRENT_ALARMS.ALARM_ID"
                  | translate
              }}
            </strong></span
          >
          <span matListItemLine>
            <a color="primary" [routerLink]="['../', element?.id]">{{
              element?.id
            }}</a>
          </span>
        </mat-list-item>
        <mat-list-item
          role="listitem"
          *ngIf="displayedColumns.includes('lineName')"
        >
          <span matListItemTitle
            ><strong>
              {{
                "SPA.ALARMS_ESCALATIONS.DASHBOARD.CURRENT_ALARMS.PRODUCTION_SECTOR"
                  | translate
              }}
            </strong></span
          >
          <span matListItemLine>
            <a color="primary" [routerLink]="['../', element?.id]">{{
              element.lineName
            }}</a>
          </span>
        </mat-list-item>
        <mat-list-item
          role="listitem"
          *ngIf="displayedColumns.includes('stationName')"
        >
          <span matListItemTitle
            ><strong>
              {{
                "SPA.ALARMS_ESCALATIONS.DASHBOARD.CURRENT_ALARMS.STATION"
                  | translate
              }}
            </strong></span
          >
          <span matListItemLine>
            <a color="primary" [routerLink]="['../', element?.id]">{{
              element.stationName
            }}</a>
          </span>
        </mat-list-item>
        <mat-list-item
          role="listitem"
          *ngIf="displayedColumns.includes('configuredAlarmName')"
        >
          <span matListItemTitle
            ><strong>
              {{
                "SPA.ALARMS_ESCALATIONS.DASHBOARD.CURRENT_ALARMS.ALARM_TYPE"
                  | translate
              }}
            </strong></span
          >
          <span matListItemLine>
            <a color="primary" [routerLink]="['../', element?.id]">{{
              getTranslatedAlarm(element)
            }}</a>
          </span>
        </mat-list-item>
        <mat-list-item
          role="listitem"
          *ngIf="displayedColumns.includes('customFieldsData')"
        >
          <span matListItemTitle
            ><strong>
              {{
                "SPA.ALARMS_ESCALATIONS.DASHBOARD.CURRENT_ALARMS.CUSTOM_FIELD"
                  | translate
              }}
            </strong></span
          >
          <span matListItemLine>
            <a color="primary" [routerLink]="['../', element?.id]">
              <div *ngFor="let customField of element.customFieldsData">
                <!-- String data -->
                <ng-container *ngIf="!isImageData(customField)">
                  <span>
                    {{ getCustomFieldName(customField) }} - {{ customField.valueAsAString }}
                  </span>
                  <br />
                </ng-container>
                <!-- Image Data -->
                <div *ngIf="isImageData(customField)">
                  <span>
                    {{ getCustomFieldName(customField) }} -
                  </span>
                  <br />
                  <div class="alarm-details-details image-container">
                    <div class="image" *ngFor="let file of customField.value">
                      <img *ngIf="file?.fileType === 'image'" [src]="getImageSrc(file, element?.id)"
                        (click)="openViewModal($event, file)">
                      <div class="video-wrapper" *ngIf="file?.fileType === 'video'" (click)="openViewModal($event, file)">
                        <video #videoPlayer [src]="getImageSrc(file, element?.id)"></video>
                        <i class="material-icons right play_arrow">play_arrow</i>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </a>
          </span>
        </mat-list-item>
        <mat-list-item
          role="listitem"
          *ngIf="displayedColumns.includes('alarmGroupName')"
        >
          <span matListItemTitle
            ><strong>
              {{
                "SPA.ALARMS_ESCALATIONS.DASHBOARD.CURRENT_ALARMS.CURRENT_ALARM_GROUP"
                  | translate
              }}
            </strong></span
          >
          <span matListItemLine>
            <a color="primary" [routerLink]="['../', element?.id]">{{
              element.alarmGroupName
            }}</a>
          </span>
        </mat-list-item>
        <mat-list-item
          role="listitem"
          *ngIf="displayedColumns.includes('created')"
        >
          <span matListItemTitle
            ><strong>
              {{
                "SPA.ALARMS_ESCALATIONS.DASHBOARD.CURRENT_ALARMS.START"
                  | translate
              }}
            </strong></span
          >
          <span matListItemLine>
            <a color="primary" [routerLink]="['../', element?.id]">{{
              element.created | customDate
            }}</a>
          </span>
        </mat-list-item>
        <mat-list-item
          role="listitem"
          *ngIf="displayedColumns.includes('duration')"
        >
          <span matListItemTitle
            ><strong>
              {{
                "SPA.ALARMS_ESCALATIONS.DASHBOARD.CURRENT_ALARMS.DURATION"
                  | translate
              }}
            </strong></span
          >
          <span matListItemLine>
            <a color="primary" [routerLink]="['../', element?.id]">{{
              calculateDuration(element.duration)
            }}</a>
          </span>
        </mat-list-item>
        <mat-list-item
          role="listitem"
          *ngIf="displayedColumns.includes('currentUser')"
        >
          <span matListItemTitle
            ><strong>
              {{
                "SPA.ALARMS_ESCALATIONS.DASHBOARD.CURRENT_ALARMS.CURRENT_USER"
                  | translate
              }}
            </strong></span
          >
          <span matListItemLine>
            <a color="primary" [routerLink]="['../', element?.id]">
              {{
                element.alarmAcceptingUser
                  ? (element.alarmAcceptingUser | formatUserName)
                  : ("SPA.ALARMS_ESCALATIONS.ALARM_PENDING" | translate)
              }}
            </a>
          </span>
        </mat-list-item>
        <mat-list-item
          role="listitem"
          *ngIf="displayedColumns.includes('actions')"
        >
          <div class="actions">
            <button
              color="primary"
              mat-raised-button
              type="button"
              *ngIf="element.status != 1"
              (click)="accept(element)"
            >
              <span>{{
                "SPA.ALARMS_ESCALATIONS.DASHBOARD.CURRENT_ALARMS.ACCEPT"
                  | translate
              }}</span>
            </button>
            <button
              color="primary"
              mat-raised-button
              type="button"
              *ngIf="
                element.status == 1 &&
                element.alarmAcceptingUserId === currentUser.id
              "
              (click)="unSubscribeAlarm(element)"
            >
              <span>{{
                "SPA.ALARMS_ESCALATIONS.DASHBOARD.CURRENT_ALARMS.UNSUBSCRIBE"
                  | translate
              }}</span>
            </button>
            <button
              color="warn"
              mat-raised-button
              type="button"
              (click)="forward(element)"
            >
              <span>{{
                "SPA.ALARMS_ESCALATIONS.DASHBOARD.CURRENT_ALARMS.FORWARD"
                  | translate
              }}</span>
            </button>
            <ng-container
              *ngIf="
                element.showEndAlarmButtonBasedOnStationMappingConfiguration
              "
            >
              <button
                color="primary"
                mat-raised-button
                type="button"
                *apUserAccess="permissions"
                (click)="endAlarm(element)"
              >
                <span>{{
                  "SPA.ALARMS_ESCALATIONS.DASHBOARD.CURRENT_ALARMS.END"
                    | translate
                }}</span>
              </button>
            </ng-container>
          </div>
        </mat-list-item>
      </mat-list>
      <mat-paginator 
        [length]="totalData" 
        [pageSizeOptions]="[5, 10, 20, 50]" showFirstLastButtons>
      </mat-paginator>
    </div>
  </div>
</mat-card>
