import { Component, OnInit, Inject } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { DomSanitizer } from '@angular/platform-browser';
import {
  MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA,
  MatLegacyDialogRef as MatDialogRef,
} from "@angular/material/legacy-dialog";
import { MAX_COMMENT_CONTENT_LENGTH, MAX_COMMENT_TITLE_LENGTH, MAX_FILE_SIZE, MAX_FILES } from '../../../constants/comment.constants';
import { InfoboxService } from '../../../services/infobox.service';

@Component({
  selector: 'app-item-comment',
  templateUrl: './item-comment.component.html',
  styleUrls: ['./item-comment.component.scss']
})

export class ItemCommentComponent implements OnInit {
  public form: FormGroup;
  public files: any[] = [];
  public deletedFiles: number[] = [];
  public maxCommentTitleLength: number = MAX_COMMENT_TITLE_LENGTH;
  public maxCommentContentLength: number = MAX_COMMENT_CONTENT_LENGTH;
  public maxFiles: number = MAX_FILES;
  public maxFileSize: number = MAX_FILE_SIZE;

  constructor(
    private fb: FormBuilder,
    private sanitizer: DomSanitizer,
    private infoboxService: InfoboxService,
    private dialogRef: MatDialogRef<ItemCommentComponent>,
    @Inject(MAT_DIALOG_DATA) public data,
  ) {}

  ngOnInit() {
    this.form = this.fb.group({
      title: ['', [Validators.required, Validators.maxLength(this.maxCommentTitleLength)]],
      comment: ['', [Validators.required, Validators.maxLength(this.maxCommentContentLength)]],
    });

    if(this.data.comment) {
      this.files = this.data.comment.files;
      this.form.patchValue({
        title: this.data.comment.title,
        comment: this.data.comment.comment,
      });
    } 
  }

  handleFile(data: any) {
    if(this.data.comment && this.data.comment.files) {
      const totalFiles = data.target.files.length + this.files.length;
      if(totalFiles > this.maxFiles) {
        this.infoboxService.error('FILE.FILE_LIMIT_EXCEEDED', { count: MAX_FILES });
        return;
      } 

      // @ts-ignore
      if (Array.from(data.target.files).some(file => file.size > this.maxFileSize)) {
        this.infoboxService.error('FILE.TOO_LARGE', { maxFileSize: MAX_FILE_SIZE });
        return;
      }
    } else {
      if(data.target.files.length > this.maxFiles || data.target.files.length + this.files.length > this.maxFiles) {
        this.infoboxService.error('FILE.FILE_LIMIT_EXCEEDED', { count: MAX_FILES });
        return;
      } 

      // @ts-ignore
      if (Array.from(data.target.files).some(file => file.size > this.maxFileSize)) {
        this.infoboxService.error('FILE.TOO_LARGE', { maxFileSize: MAX_FILE_SIZE });
        return;
      } 
    }

    for(const file of data.target.files) {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = (_event) => {
        file['src'] = this.sanitizer.bypassSecurityTrustResourceUrl(reader.result.toString());
        file['originalName'] = file.name;
      };
      this.files.push(file);
    }
  } 
  
  removeFile(file: any, index: number) {
    this.files = this.files.filter((_, i) => i !== index);
    if(file.id && !this.deletedFiles.includes(file.id)) {
      this.deletedFiles.push(file.id);
    }
  }

  save() {
    if(this.form.invalid) {
      return;
    }
    this.dialogRef.close(
      {
        title: this.form.value.title,
        comment: this.form.value.comment,
        files: this.files, 
        deletedFiles: this.deletedFiles,
      }
    );
  }
}