/**
 * Remember to add migration (if needed) when you change something here
 */
export enum UserPermissionsEnum {
  Public = 0,
  SuperAdmin = 5,
  UserManagement = 10,
  ChartsPage = 20,
  ShiftManagement = 40,
  LineManagement = 50,
  ReportsPage = 70,
  StationManagement = 80,
  ProductsManagement = 90,
  ProductItemTrash = 120,
  StationDisorderAutologin = 130,
  EventsAndActionsManagement = 140,
  AESupervisor = 150,
  AEGroupsManagement = 160,
  AEDisorderReasonManagement = 170,
  AEEndAlarmFromDashboardManagement = 410,
  AEAlarmDashboardManagement = 420,
  AEAlarmsListingManagement = 430,
  AEForwardAlarmManagement = 440,
  AECommentOnDetailsPage = 480,
  DeviceConfigurationManagement = 190,
  WorkstepsManagement = 200,
  RemoteStationView = 210,
  WorkInstructionsManagement = 220,
  ProductionFlowManagement = 230,
  StationWorker = 240,
  StationLogout = 250,
  AndonManagement = 260,
  ProductItemDetailsPage = 270,
  ProductItemDataManipulationPage = 280,
  ProductItemCommentOnDetailsPage = 281,
  StationProductItemIncludeLater = 290,
  SystemSettingsManagement = 300,
  EmailSettingsManagement = 301,
  FtpSettingsManagement = 302,
  BackupSettingsManagement = 310,
  StationProductItemDataManipulationPage = 320,
  IOModuleConfig = 330,
  IOMapping = 340,
  LineStop = 350,
  LineText = 360,
  DashboardManagement = 370,
  ClockSettingsManagement = 380,
  AEManagement = 400,
  GeneralSettingsManagement = 460,
  EndWorkstepsAlsoIfErrorOccurred = 450,
  DeleteProductsManagement = 470
}