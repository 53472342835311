import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-spinner',
  templateUrl: './spinner.component.html',
  styleUrls: ['./spinner.component.scss']
})
export class SpinnerComponent implements OnInit {
  @Input() isVisible: boolean = false; // Default is not visible
  @Input() size: number = 60;
  @Input() backdrop: boolean = true;
  borderWidth: number = 8;

  ngOnInit(): void {
    if (this.size <= 25) {
      this.borderWidth = Math.max(2, Math.floor(this.size / 5));
    } else if (this.size <= 50) {
      this.borderWidth = Math.max(2, Math.floor(this.size / 6));
    } else if (this.size <= 100) {
      this.borderWidth = Math.floor(this.size / 8);
    } else {
      this.borderWidth = Math.floor(this.size / 12);
    }
  }
}
