export enum AlarmEventEnum {
  OCCURRED = 0,
  DISORDER_SUPERVISOR_SUBSCRIBED = 1,
  DISORDER_SUPERVISOR_CANCELED = 2,
  DISORDER_SUPERVISOR_FORWARDED = 3,
  DISORDER_SUPERVISOR_LOGIN_AT_STATION = 4,
  DISORDER_SUPERVISOR_CLASSIFIED = 5,
  RESOLVED = 6,
  DISORDER_SUPERVISOR_LOGOUT_AT_STATION = 7,
  DISORDER_SUPERVISOR_ESCALATED= 8
}