<page-header mat-dialog-title [titleTranslate]="data.title"></page-header>
<form [formGroup]="form" (ngSubmit)="save()">
  <mat-dialog-content fxLayout="column">
    <mat-form-field fxFlex="auto" appearance="outline">
      <mat-label>{{ 'COMMON.COMMENT.TITLE' | translate }}</mat-label>
      <input matInput type="text" formControlName="title">
      <mat-error *ngIf="form.get('title')?.hasError('required')">
        {{ 'COMMON.COMMENT.TITLE_EMPTY' | translate }}
      </mat-error>
      <mat-error *ngIf="form.get('title')?.hasError('maxlength')">
        {{ 'COMMON.COMMENT.TITLE_TOO_LONG' | translate: {count: maxCommentTitleLength} }}
      </mat-error>
    </mat-form-field>
    <mat-form-field fxFlex="auto" appearance="outline">
      <mat-label>{{ 'COMMON.COMMENT.COMMENT' | translate }}</mat-label>
      <textarea matInput rows="3" formControlName="comment"></textarea>
      <mat-error *ngIf="form.get('comment')?.hasError('required')">
        {{ 'COMMON.COMMENT.COMMENT_EMPTY' | translate }}
      </mat-error>
      <mat-error *ngIf="form.get('comment')?.hasError('maxlength')">
        {{ 'COMMON.COMMENT.COMMENT_TOO_LONG' | translate: {count: maxCommentContentLength} }}
      </mat-error>
    </mat-form-field>
    <mat-form-field fxFlex="auto" appearance="outline" class="file-input">
      <mat-label>{{ 'COMMON.COMMENT.FILES' | translate }}</mat-label>
      <ngx-mat-file-input (change)="handleFile($event)" multiple
      ></ngx-mat-file-input>
      <mat-icon matSuffix>folder</mat-icon>
    </mat-form-field>
    <span style="color: gray; font-size: 12px;">{{ 'FILE.FILE_UPLOAD_RESTRICTIONS' | translate: {count: maxFiles, maxFileSize: maxFileSize | byteFormat} }}</span>

    <div *ngIf="files.length">
      <div fxLayout="column" fxLayoutGap="16px">
        <ng-container *ngFor="let file of files; let i = index">
          <div fxLayout="row" fxLayoutGap="16px" fxLayoutAlign="space-between center">
            <div fxLayout="row" fxLayoutGap="8px">
              <mat-icon matSuffix>folder</mat-icon>
              <span>{{ file.originalName }}</span>
          </div>
            <button color="warn" type="button" mat-raised-button (click)="removeFile(file, i)">
              <mat-icon>delete</mat-icon>
            </button>
          </div>
        </ng-container>
      </div>
    </div>
  </mat-dialog-content>
  <mat-dialog-actions align="end">
    <button mat-button mat-button color="warn" type="button" mat-dialog-close>{{ 'COMMON.CANCEL' | translate }}</button>
    <button mat-button mat-raised-button color="primary" type="submit" [disabled]="form.invalid">{{ 'COMMON.SAVE' | translate }}</button>
  </mat-dialog-actions>
</form>