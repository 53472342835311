import { Pipe, PipeTransform } from '@angular/core';
import { UserInterface } from '../../auth/interfaces/user.interface';

@Pipe({
    name: 'formatUserName'
})
export class FormatUserNamePipe implements PipeTransform {
    transform(user: UserInterface): string {
        if (!user) return '-';
        return `${user.username} - ${user.firstname || ''} ${user.name || ''}`.trim();
    }
}