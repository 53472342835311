<page-header titleTranslate="SPA.SHIFTS.CREATE" *ngIf="isNew()"></page-header>
<page-header titleTranslate="SPA.SHIFTS.EDIT" *ngIf="!isNew()"></page-header>
<div mat-dialog-content>
    <div class="content-modal" [formGroup]="form">
        <div fxLayout="row" class="content-row" fxLayout.lt-sm="column" fxLayoutGap="16px" fxLayoutGap.lt-sm="0">
            <mat-form-field fxFlex="auto" appearance="outline" class="vex-flex-form-field" color="primary" [style.fontSize.px]="12">
                <mat-label>{{ 'COMMON.NAME' | translate }}</mat-label>
                <input matInput required formControlName="name" [readOnly]="isActive()">
            </mat-form-field>
        </div>
        <div fxLayout="row" class="content-row" fxLayout.lt-sm="column" fxLayoutGap="16px" fxLayoutGap.lt-sm="0">
            <mat-form-field fxFlex="auto" appearance="outline" class="vex-flex-form-field width-equal datetime" color="primary" [style.fontSize.px]="12">
                <mat-label>{{ 'SPA.SHIFTS.FROM' | translate }}</mat-label>
                <input matInput required [ngxMatDatetimePicker]="picker" formControlName="start" [min]="minDateFrom()" (dateChange)="changeEndDate()">
                <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                <ngx-mat-datetime-picker #picker [showSpinners]="true" [showSeconds]="true"></ngx-mat-datetime-picker>
                <mat-hint>{{ 'SPA.SHIFTS.MAX_SHIFT_REACHED' | translate }}</mat-hint>
            </mat-form-field>
            <mat-form-field fxFlex="auto" appearance="outline" class="vex-flex-form-field width-equal datetime" color="primary" [style.fontSize.px]="12">
                <mat-label>{{ 'SPA.SHIFTS.TO' | translate }}</mat-label>
                <input matInput required [ngxMatDatetimePicker]="picker2" formControlName="end"  [min]="minDateTo()" [max]="maxDateTo()">
                <mat-datepicker-toggle matSuffix [for]="picker2"></mat-datepicker-toggle>
                <ngx-mat-datetime-picker #picker2 [showSpinners]="true" [showSeconds]="true"></ngx-mat-datetime-picker>
            </mat-form-field>
        </div>
        <div *ngIf="startDateError || endDateError">
            <span class="dates-error">{{ 'SPA.SHIFTS.DATE_CHANGE_ERROR_FOR_PERIODIC_SHIFT' | translate }}</span>
        </div>
        <div *ngIf="isNew()" fxLayout="row" class="content-row periodicity" fxLayout.lt-sm="column" fxLayoutGap="16px" fxLayoutGap.lt-sm="0">
            <mat-label>{{ 'SPA.SHIFTS.PERIODICITY' | translate }}</mat-label>
            <mat-checkbox [(ngModel)]="isPeriodical" [ngModelOptions]="{standalone: true}">
            </mat-checkbox>
        </div>
        <app-periodicity *ngIf="isPeriodical && isNew()" [period]="form.get('period').value" [isShiftType]="true"></app-periodicity>
        <div fxLayout="row" class="content-row" fxLayout.lt-sm="column" fxLayoutGap="16px" fxLayoutGap.lt-sm="0">
            <mat-form-field fxFlex="auto" appearance="outline" class="vex-flex-form-field" color="primary" [style.fontSize.px]="12">
                <mat-label>{{ 'COMMON.LINES' | translate }}</mat-label>
                <mat-select  multiple formControlName="lineIds" required>
                    <mat-option *ngFor="let line of data.lines" [disabled]="shouldDisableLineOption(line.id) || isActive()"
                                [value]="line.id">{{line.name}}</mat-option>
                </mat-select>
            </mat-form-field>
        </div>
        <div fxLayout="row" class="content-row" fxLayout.lt-sm="column" fxLayoutGap="16px" fxLayoutGap.lt-sm="0">
            <mat-form-field fxFlex="auto" appearance="outline" class="vex-flex-form-field" color="primary" [style.fontSize.px]="12">
                <mat-label>{{ 'COMMON.USERS' | translate }}</mat-label>
                <mat-select  multiple formControlName="userIds">
                    <mat-option *ngFor="let user of data.users" [disabled]="shouldDisableUserOption(user.id) || isActive()"
                                [value]="user.id">{{user | formatUserName}}</mat-option>
                </mat-select>
            </mat-form-field>
        </div>
        <div fxLayout="row" class="content-row pauses" fxLayout.lt-sm="column" fxLayoutGap="16px" fxLayoutGap.lt-sm="0">
            <page-header titleTranslate="SPA.SHIFTS.PAUSES"></page-header>
            <button color="primary" mat-raised-button type="button" class="small-btn"
                    (click)="addPause()" [disabled]="isFinished() || isActive()">
                <mat-icon class="ltr:-ml-1 rtl:-mr-1 rtl:ml-2"
                          inline="true">add</mat-icon>
            </button>
        </div>
        <div fxLayout="row" class="content-row pause" fxLayout.lt-sm="column" fxLayoutGap="16px" fxLayoutGap.lt-sm="0"
             *ngFor="let pause of pauses.controls; let i = index" [formGroup]="pause">
            <mat-form-field fxFlex="auto" appearance="outline" class="vex-flex-form-field" color="primary" [style.fontSize.px]="12">
                <mat-label>{{ 'COMMON.NAME' | translate }}</mat-label>
                <input matInput required formControlName="name">
            </mat-form-field>
            <mat-form-field fxFlex="auto" appearance="outline" class="vex-flex-form-field width-equal datetime" color="primary" [style.fontSize.px]="12">
                <mat-label>{{ 'SPA.SHIFTS.FROM' | translate }}</mat-label>
                <input matInput required [ngxMatDatetimePicker]="picker3" formControlName="start" [min]="minPauseDateFrom()" [max]="maxPauseDateTo()">
                <mat-datepicker-toggle matSuffix [for]="picker3"></mat-datepicker-toggle>
                <ngx-mat-datetime-picker #picker3 [showSpinners]="true" [showSeconds]="true"></ngx-mat-datetime-picker>
            </mat-form-field>
            <mat-form-field fxFlex="auto" appearance="outline" class="vex-flex-form-field width-equal datetime" color="primary" [style.fontSize.px]="12">
                <mat-label>{{ 'SPA.SHIFTS.TO' | translate }}</mat-label>
                <input matInput required [ngxMatDatetimePicker]="picker4" formControlName="end" [min]="minPauseDateTo(i)" [max]="maxPauseDateTo()">
                <mat-datepicker-toggle matSuffix [for]="picker4"></mat-datepicker-toggle>
                <ngx-mat-datetime-picker #picker4 [showSpinners]="true" [showSeconds]="true"></ngx-mat-datetime-picker>
            </mat-form-field>
            <button color="primary" mat-raised-button type="button" class="small-btn delete-btn"
                    (click)="removePause(i)" [disabled]="pause.get('start').disabled || isActive()">
                <mat-icon class="ltr:-ml-1 rtl:-mr-1 rtl:ml-2"
                          inline="true">delete</mat-icon>
            </button>
        </div>
        <small class="error" *ngIf="!pausesCorrect()">{{ 'SPA.SHIFTS.INCORRECT_PAUSES' | translate }}</small>

        <color-picker [disabled]="isFinished() || isActive()" [absolute]="false" label="SPA.SHIFTS.LABEL_COLOR" [color]="form.value.color" [hideWhite]="true" (changed)="changeColor($event)"></color-picker>

    </div>
    <ngx-loading [show]="data.shiftService?.loading || loading" [config]="{ backdropBorderRadius: '3px' }"></ngx-loading>
</div>
<div mat-dialog-actions>
    <div class="footer" fxLayout="row" fxLayoutAlign="end center" fxLayoutGap="8px" *ngIf="isFinished()">
        <small class="error">{{ 'SPA.SHIFTS.SHIFT_FINISHED' | translate }}</small>
    </div>
    <div class="footer" fxLayout="row" fxLayoutAlign="end center" fxLayoutGap="8px" *ngIf="isActive()">
        <small class="error">Shift is active, you can not edit.</small>
    </div>
    <div class="collisions" *ngIf="collisionErrors.length > 0">
        <small class="error" *ngFor="let error of collisionErrors">
            <strong>{{getLineName(error.lineId)}}</strong> {{ 'SPA.SHIFTS.HAS_COLLISION' | translate }} <strong>{{error.shiftName}}</strong> ({{error.start | customDate}} - {{error.end | customDate}})
        </small>
    </div>
    <div class="footer btns" fxLayout="row" fxLayoutGap="8px">
        <div class="left">
            <button color="warn" mat-raised-button (click)="stopShift()" *ngIf="canStop()"
                    type="button">{{ 'SPA.SHIFTS.STOP_SHIFT' | translate }}
            </button>
            <button type="button" *ngIf="canDelete()"
                    color="warn" mat-raised-button (click)="deleteShift()">{{ 'SPA.SHIFTS.DELETE_SHIFT' | translate }}
            </button>
        </div>
        <div class="right">
            <button color="primary" mat-button (click)="close()"
                    type="button">{{ 'COMMON.CANCEL' | translate }}
            </button>
            <button type="button" [disabled]="shouldBlockSave()"
                    color="primary" mat-raised-button (click)="save()">{{ 'COMMON.SAVE' | translate }}
            </button>
        </div>
    </div>
</div>
