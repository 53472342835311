<div fxLayout="row" fxLayout.lt-sm="column" fxLayoutGap="16px" fxLayoutGap.lt-sm="0">
    <mat-form-field fxFlex="auto" appearance="outline" class="vex-flex-form-field" color="primary">
        <mat-label>{{ 'COMMON.SEARCH' | translate }}</mat-label>
        <input matInput type="text" name="searchPhrase" [(ngModel)]="searchPhrase" (ngModelChange)="search()">
        <mat-icon class="mr-2" matPrefix>search</mat-icon>
    </mat-form-field>
</div>

<div class="table" #table>
    <table mat-table [dataSource]="dataSource" #table resizeTable [columns]="columns" [displayedColumns]="displayedColumns">
        <ng-container matColumnDef="role">
            <th mat-header-cell *matHeaderCellDef>{{ 'SPA.USERS.ROLE' | translate }}
                <span class="resize-handle" (mousedown)="onResizeColumn($event, 'role')"></span>
            </th>
            <td mat-cell *matCellDef="let user"> {{user.role.role | titlecase}} </td>
        </ng-container>

        <ng-container matColumnDef="username">
            <th mat-header-cell *matHeaderCellDef>{{ 'SPA.USERS.USERNAME' | translate }}
                <span class="resize-handle" (mousedown)="onResizeColumn($event, 'username')"></span>
            </th>
            <td mat-cell *matCellDef="let user"> {{user.username}} </td>
        </ng-container>

        <ng-container matColumnDef="firstname">
            <th mat-header-cell *matHeaderCellDef>{{ 'SPA.USERS.FIRST_NAME' | translate }}
                <span class="resize-handle" (mousedown)="onResizeColumn($event, 'firstname')"></span>
            </th>
            <td mat-cell *matCellDef="let user"> {{user.firstname}} </td>
        </ng-container>

        <ng-container matColumnDef="name">
            <th mat-header-cell *matHeaderCellDef>{{ 'SPA.USERS.LAST_NAME' | translate }}
                <span class="resize-handle" (mousedown)="onResizeColumn($event, 'name')"></span>
            </th>
            <td mat-cell *matCellDef="let user"> {{user.name}} </td>
        </ng-container>

        <ng-container matColumnDef="email">
            <th mat-header-cell *matHeaderCellDef>{{ 'SPA.USERS.EMAIL' | translate }}
                <span class="resize-handle" (mousedown)="onResizeColumn($event, 'email')"></span>
            </th>
            <td mat-cell *matCellDef="let user"> {{user.email}} </td>
        </ng-container>

        <ng-container matColumnDef="coin">
            <th mat-header-cell *matHeaderCellDef>{{ 'SPA.USERS.COIN' | translate }}
                <span class="resize-handle" (mousedown)="onResizeColumn($event, 'coin')"></span>
            </th>
            <td mat-cell *matCellDef="let user"> {{user.coin}} </td>
        </ng-container>
        
        <ng-container matColumnDef="actions">
            <th mat-header-cell *matHeaderCellDef>
                <span class="resize-handle" (mousedown)="onResizeColumn($event, 'actions')"></span>
            </th>
            <td mat-cell *matCellDef="let user"> 
                <div fxLayout="row" fxLayout.lt-sm="column" fxLayoutGap="16px" fxLayoutGap.lt-sm="0">
                    <button color="primary" mat-raised-button type="button"
                            [routerLink]="['/users/list', user.id]">
                        <mat-icon class="ltr:-ml-1 rtl:-mr-1 rtl:ml-2" inline="true">edit</mat-icon>
                    </button>
                    <button color="warn" mat-raised-button type="button" [disabled]="disableDelete(user)" (click)="removeUser(user)">
                        <mat-icon class="ltr:-ml-1 rtl:-mr-1 rtl:ml-2" inline="true">delete</mat-icon>
                    </button>
                </div>
            </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>

        <ngx-loading [show]="loading" [config]="{ backdropBorderRadius: '3px' }"></ngx-loading>
    </table> 
</div>

<button mat-fab class="ap-fab" [routerLink]="['/users/list/add']">
    <mat-icon>add</mat-icon>
</button>
