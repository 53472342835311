import { DomSanitizer, SafeUrl } from '@angular/platform-browser';
import { Component, Inject } from '@angular/core';
import {
  MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA,
  MatLegacyDialogRef as MatDialogRef,
} from '@angular/material/legacy-dialog';
import { FileTypeEnum } from '../../../constants/mime-types.constants';

@Component({
  selector: 'app-file-preview-modal',
  templateUrl: './file-preview-modal.component.html',
  styleUrls: ['./file-preview-modal.component.scss'],
})

export class FilePreviewModalComponent {
  public mediaUrl: SafeUrl;
  public filename: string;
  public fileTypeEnum = FileTypeEnum

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private domSanitizer: DomSanitizer,
    public dialogRef: MatDialogRef<FilePreviewModalComponent>
  ) {}

  ngOnInit(): void {
    this.generateMediaUrl();
  }

  async generateMediaUrl() {
    try {
      if(this.data && this.data.file) {
        const media = await fetch(this.data.file.src);
        const mediaBlog = await media.blob();
        const mediaURL = URL.createObjectURL(mediaBlog);
        this.mediaUrl = this.domSanitizer.bypassSecurityTrustUrl(mediaURL);
        this.filename = `${new Date().getTime()}`;
      }
    } catch (error) {
      console.error(error);
    }
  };

  close(result = undefined) {
    this.dialogRef.close(result);
  }
}