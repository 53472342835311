<page-header titleTranslate="SPA.WORK_INSTRUCTIONS.HEADER"></page-header>
<div fxLayout="row" fxLayout.lt-sm="column" fxLayoutGap="16px" fxLayoutGap.lt-sm="0">
    <mat-form-field fxFlex="auto" appearance="outline" class="vex-flex-form-field" color="primary">
        <mat-label>{{ 'COMMON.SEARCH' | translate }}</mat-label>
        <input matInput type="text" name="searchPhrase" [(ngModel)]="searchPhrase" (ngModelChange)="search()">
        <mat-icon class="mr-2" matPrefix>search</mat-icon>
    </mat-form-field>
</div>

<div class="table" #table>
    <table mat-table [dataSource]="dataSource" resizeTable [columns]="columns" [displayedColumns]="displayedColumns">
        <ng-container matColumnDef="name">
            <th mat-header-cell *matHeaderCellDef>{{ 'SPA.WORK_INSTRUCTIONS.NAME' | translate }}
                <span class="resize-handle" (mousedown)="onResizeColumn($event, 'name')"></span>
            </th>
            <td mat-cell *matCellDef="let workInstruction"> {{workInstruction.name | titlecase}} </td>
        </ng-container>

        <ng-container matColumnDef="folder">
            <th mat-header-cell *matHeaderCellDef>{{ 'SPA.WORK_INSTRUCTIONS.FOLDER' | translate }}
                <span class="resize-handle" (mousedown)="onResizeColumn($event, 'folder')"></span>
            </th>
            <td mat-cell *matCellDef="let workInstruction"> {{ getFolderName(workInstruction.folder) }} </td>
        </ng-container>

        <ng-container matColumnDef="stations">
            <th mat-header-cell *matHeaderCellDef>{{ 'SPA.WORK_INSTRUCTIONS.STATION' | translate }}
                <span class="resize-handle" (mousedown)="onResizeColumn($event, 'stations')"></span>
            </th>
            <td mat-cell *matCellDef="let workInstruction"> {{ getStationNames(workInstruction) }} </td>
        </ng-container>

        <ng-container matColumnDef="actions">
            <th mat-header-cell *matHeaderCellDef>
                <span class="resize-handle" (mousedown)="onResizeColumn($event, 'actions')"></span>
            </th>
            <td mat-cell *matCellDef="let workInstruction"> 
                <div fxLayout="row" fxLayout.lt-sm="column" fxLayoutGap="16px" fxLayoutGap.lt-sm="0">
                    <button color="primary" mat-raised-button type="button"
                            (click)="editWorkInstruction(workInstruction)">
                        <mat-icon class="ltr:-ml-1 rtl:-mr-1 rtl:ml-2"
                                inline="true">create</mat-icon>
                    </button>
                    <button class="bg-blue" mat-raised-button type="button"
                            [routerLink]="['/line', lineId, 'work-instructions', 'view', workInstruction.id]">
                        <mat-icon class="ltr:-ml-1 rtl:-mr-1 rtl:ml-2"
                                inline="true">remove_red_eye</mat-icon>
                    </button>
                    <button color="warn" mat-raised-button type="button" (click)="deleteWorkInstruction(workInstruction)">
                        <mat-icon class="ltr:-ml-1 rtl:-mr-1 rtl:ml-2"
                                inline="true">delete</mat-icon>
                    </button>
                </div>
            </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>

        <ngx-loading [show]="loading" [config]="{ backdropBorderRadius: '3px' }"></ngx-loading>
    </table> 
</div>
<button mat-fab class="ap-fab" [routerLink]="['/line', lineId, 'work-instructions', 'add']">
    <mat-icon>add</mat-icon>
</button>
