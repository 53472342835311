import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'truncateEllipsis'
})
export class TruncateEllipsisPipe implements PipeTransform {
  transform(text: string, tileWidth?: number): string {
    let characters = tileWidth ? Math.floor(tileWidth / 15) : 15;
    
    if (text?.length > characters) {
      return text.substr(0, characters - 1) + '...';
    } else {
      return text;
    }
  }
}
