import { Component, OnInit } from '@angular/core';
import { BreadcrumbComponent } from '../../shared/components/breadcrumb.component';
import { ActivatedRoute, Router } from '@angular/router';
import { Location } from '@angular/common';
import { FormControl, Validators } from '@angular/forms';
import { InfoboxService } from '../../../../common/shared/services/infobox.service';
import { GeneralSettingsService} from '../general-settings/serivces/general-settings.service';
import { USER_LOGOUT_AFTER_INTERVAL_KEY, USER_BENNER_SETTINGS_KEY } from '../../../../common/general-settings/constants/general-settings-key.constant';
import { GeneralSettingsDataInterface } from '../../../../common/general-settings/interfaces/general-settings-data.interface';
import { AuthService } from '../../auth/auth.service';
import { LayoutService } from '../../../@vex/services/layout.service';
import { VERSION } from '../../../environments/version';

const DEFAULT_USER_LOGOUT_AFTER_TIME = 5;
const DEFAULT_USER_BANNER_TEXT = null;

@Component({
  selector: 'app-general-settings',
  templateUrl: './general-settings.component.html',
  styleUrls: ['./general-settings.component.scss']
})
export class GeneralSettingsComponent extends BreadcrumbComponent implements OnInit {
  userLogoutAfter = new FormControl(null, [Validators.required, Validators.min(1)]);
  userBannerText = new FormControl(null, Validators.required);
  showBanner = false;
  version: string = VERSION || '';

  constructor(
    private location: Location,
    public route: ActivatedRoute,
    public router: Router,
    private infoboxService: InfoboxService,
    private generalSettingsService: GeneralSettingsService,
    public authService: AuthService,
    private layoutService: LayoutService
  ) {
    super(route, router);
  }

  ngOnInit(): void {
    this.initBreadcrumbs();
    this.getGeneralSettingsData();
  }

  save() {
    if (this.isInvalid()) {
      return;
    }

    let data: GeneralSettingsDataInterface = {
      "settingKey": USER_LOGOUT_AFTER_INTERVAL_KEY,
      "settingValue": this.userLogoutAfter.value
    }
    this.generalSettingsService.updateGeneralSettings(data).subscribe(() => {
      this.infoboxService.success('COMMON.SAVED');
    });
  }

  saveBannerText() {
    if (this.isInvalidBannerText()) {
      return;
    }
    const bannerData ={
      showBanner: this.showBanner,
      bannerText: this.userBannerText.value,
    }
    let data: GeneralSettingsDataInterface = {
      "settingKey": USER_BENNER_SETTINGS_KEY,
      "settingValue": JSON.stringify(bannerData),
    }
    this.generalSettingsService.updateGeneralSettings(data).subscribe(() => {
      if(bannerData.showBanner) {
        this.layoutService.showBanner();
      } else {
        this.layoutService.hideBanner();
      }
      this.infoboxService.success('COMMON.SAVED');
    });
  }

  goBack() {
      this.location.back();
  }

  isInvalid(): boolean{
    return this.userLogoutAfter?.invalid || false;
  }

  isInvalidBannerText(): boolean{
    return this.userBannerText?.invalid && this.showBanner || false;
  }

  restrictKeyword(event){
    const invalidKeys = ['.', '-', '+'];
    if(invalidKeys.includes(event.key)) {
      event.preventDefault();
    } 
  }

  getGeneralSettingsData() {
    this.generalSettingsService.getGeneralSettingValueByKey(USER_LOGOUT_AFTER_INTERVAL_KEY).subscribe((result) => {
      if(result) {
        this.userLogoutAfter.setValue(result.settingValue);
      } else {
        this.userLogoutAfter.setValue(DEFAULT_USER_LOGOUT_AFTER_TIME);
      }
    });

    this.generalSettingsService.getGeneralSettingValueByKey(USER_BENNER_SETTINGS_KEY).subscribe((result) => {
      if(result) {
        let data = JSON.parse(result.settingValue);        
        this.showBanner = data.showBanner;
        this.userBannerText.setValue(data.bannerText);
      } else {
        this.showBanner = false;
        this.userBannerText.setValue(DEFAULT_USER_BANNER_TEXT);
      }
    });
  }
}