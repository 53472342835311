import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'secondsToTimeFormat'
})
export class SecondsToTimeFormatPipe implements PipeTransform {

  transform(seconds: number): string {
    if (isNaN(seconds) || seconds < 0) return 'Invalid time';

    const days = Math.floor(seconds / (24 * 3600));
    const hours = Math.floor((seconds % (24 * 3600)) / 3600);
    const minutes = Math.floor((seconds % 3600) / 60);
    const remainingSeconds = seconds % 60;

    let result = '';

    result += `${days} day${days > 1 ? 's' : ''}, `;
    result += `${this.padZero(hours)} hour${hours > 1 ? 's' : ''}, `;
    result += `${this.padZero(minutes)} minute${minutes > 1 ? 's' : ''}, `;
    result += `${this.padZero(remainingSeconds)} second${remainingSeconds > 1 ? 's' : ''}`;

    return result.trim().replace(/,(\s*)$/, '');
  }

  private padZero(value: number): string {
    if(value > 0) {
      return value.toString().padStart(2, '0');
    }
    return value.toString();
  }
}
