<vex-secondary-toolbar>
    <vex-breadcrumbs [crumbs]="crumbs" class="flex-auto"></vex-breadcrumbs>
</vex-secondary-toolbar>
<div class="page-content">
    <div class="row ap-card shadow">
        <div class="flex space-between">
            <page-header titleTranslate="SPA.BACKUP.LABEL"></page-header>
            <button class="btn btn-small" color="warn" mat-raised-button (click)="makeInstantBackup()" [disabled]="disabledInstantBackup">
                {{ 'SPA.BACKUP.MAKE_BACKUP' | translate }}
            </button>
        </div>
        <div class="periodicity" [formGroup]="form" (ngSubmit)="saveBackupData()" *ngIf="showForm && form.get('period').value">
            <div fxLayout="row" class="content-row" fxLayout.lt-sm="column" fxLayoutGap="16px" fxLayoutGap.lt-sm="0">
                <mat-form-field fxFlex="auto" appearance="outline" class="vex-flex-form-field" color="primary" [style.fontSize.px]="12">
                    <mat-label>{{ 'SPA.BACKUP.PREFIX_NAME' | translate }}</mat-label>
                    <input matInput required formControlName="name">
                </mat-form-field>
            </div>
            <app-periodicity [period]="form.get('period').value"></app-periodicity>
            <div class="row mb-8">
                <div class="col s12">
                    <div fxLayout="row" fxLayout.lt-sm="column" fxLayoutGap="16px" fxLayoutGap.lt-sm="0" class="mt-10">
                        <div fxFlex="auto" fxLayoutAlign="start center" fxLayoutGap="16px">
                            <button mat-raised-button type="submit" color="warn" (click)="stop()">
                                {{ 'SPA.BACKUP.STOP_SCHEDULER' | translate }}
                            </button>
                        </div>
                        <div fxFlex="auto" fxLayoutAlign="end center" fxLayoutGap="16px">
                            <button color="primary" mat-raised-button type="submit" [disabled]="!form.valid" (click)="saveBackupData()">
                                {{ getFormButtonText() | translate }}
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="no-backup" *ngIf="!showForm">
            <button color="primary" mat-raised-button type="submit" (click)="showForm = true">
                {{ 'SPA.BACKUP.CREATE_BACKUP_SCHEDULER' | translate }}
            </button>
        </div>
        <page-header titleTranslate="SPA.BACKUP.CREATED_BACKUPS"></page-header>
        <div class="table">
            <table mat-table [dataSource]="dataSource" #table resizeTable [columns]="columns" [displayedColumns]="displayedColumns">
                <ng-container matColumnDef="start">
                    <th mat-header-cell *matHeaderCellDef>{{ 'SPA.BACKUP.STARTED' | translate }}
                        <span class="resize-handle" (mousedown)="onResizeColumn($event, 'start')"></span>
                    </th>
                    <td mat-cell *matCellDef="let event"> {{event.start | customDate}} </td>
                </ng-container>
            
                <ng-container matColumnDef="end">
                    <th mat-header-cell *matHeaderCellDef>{{ 'SPA.BACKUP.ENDED' | translate }}
                        <span class="resize-handle" (mousedown)="onResizeColumn($event, 'end')"></span>
                    </th>
                    <td mat-cell *matCellDef="let event"> {{ event.end | customDate }} </td>
                </ng-container>
            
                <ng-container matColumnDef="fileName">
                    <th mat-header-cell *matHeaderCellDef>{{ 'SPA.BACKUP.NAME' | translate }}
                        <span class="resize-handle" (mousedown)="onResizeColumn($event, 'fileName')"></span>
                    </th>
                    <td mat-cell *matCellDef="let event"> {{ event.fileName }} </td>
                </ng-container>

                <ng-container matColumnDef="isFinished">
                    <th mat-header-cell *matHeaderCellDef>{{ 'SPA.BACKUP.STATUS' | translate }}
                        <span class="resize-handle" (mousedown)="onResizeColumn($event, 'isFinished')"></span>
                    </th>
                    <td mat-cell *matCellDef="let event"> 
                        <span *ngIf="event.isFinished">{{ 'SPA.BACKUP.DONE'| translate}}</span>
                        <span *ngIf="!event.isFinished">{{ 'SPA.BACKUP.IN_PROGRESS'| translate}}</span>    
                    </td>
                </ng-container>
            
                <ng-container matColumnDef="actions">
                    <th mat-header-cell *matHeaderCellDef>
                        <span class="resize-handle" (mousedown)="onResizeColumn($event, 'actions')"></span>
                    </th>
                    <td mat-cell *matCellDef="let event"> 
                        <div fxLayout="row" fxLayout.lt-sm="column" fxLayoutGap="8px" fxLayoutGap.lt-sm="0" *ngIf="event.isFinished">
                            <button color="primary" mat-raised-button type="button" (click)="download(event)">
                                <mat-icon class="ltr:-ml-1 rtl:-mr-1 rtl:ml-2"
                                        inline="true">save_alt</mat-icon>
                            </button>
                        </div>
                    </td>
                </ng-container>
            
                <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
                <ngx-loading [show]="loading" [config]="{ backdropBorderRadius: '3px' }"></ngx-loading>
            </table> 
        </div>
    </div>
</div>
