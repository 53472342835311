<div *ngIf="!showList() && !loading" class="text-center mt-6 mb-6">
    <h5>{{ 'SPA.DEVICE.NO_DEVICES' | translate }}</h5>
</div>
<div *ngIf="showList()">
    <div *apConfiguration="[ConfigurationEnum.STATION_UPDATE]">
        <div mat-dialog-actions align="end">
            <button color="primary" mat-raised-button type="button" *ngIf="!isSuperAdmin()"
                (click)="deviceUpdaterService.updateDevices()">
                {{ 'SPA.DEVICE.UPDATE_ALL' | translate }}
                <mat-icon class="rtl:-mr-1" inline="true">sync</mat-icon>
            </button>
        </div>
    </div>
</div>

<div *ngIf="showList()" style="width: 100%; overflow: auto;">
    <table mat-table #table [dataSource]="dataSource" matSort cdkDropList [cdkDropListData]="dataSource" (cdkDropListDropped)="drop($event)" [cdkDropListDisabled]="disableDrag()"
    resizeTable [columns]="columns" [displayedColumns]="displayedColumns" class="device-list-table" (columnDataChanged)="onColumnDataChanged($event)">
        <ng-container matColumnDef="reorder" *ngIf="!disableDrag()">
            <th mat-header-cell *matHeaderCellDef>
                <span class="resize-handle" (mousedown)="onResizeColumn($event, 'reorder')"></span>
            </th>
            <td mat-cell *matCellDef="let device">
                <mat-icon cdkDragHandle>drag_handle</mat-icon>
            </td>
        </ng-container>

        <ng-container matColumnDef="version">
            <th mat-header-cell *matHeaderCellDef>
                {{ "SPA.DEVICE.VERSION" | translate }}
                <span class="resize-handle" (mousedown)="onResizeColumn($event, 'version')"></span>
            </th>
            <td mat-cell *matCellDef="let device">
                <span>{{device.version}}</span>
            </td>
        </ng-container>

        <ng-container matColumnDef="ip" *ngIf="isSuperAdmin()">
            <th mat-header-cell *matHeaderCellDef>
                {{ "SPA.DEVICE.IP_ADDRESS" | translate }}
                <span class="resize-handle" (mousedown)="onResizeColumn($event, 'ip')"></span>
            </th>
            <td mat-cell *matCellDef="let device">
                <span>{{device?.ip}}</span>
            </td>
        </ng-container>

        <ng-container matColumnDef="name">
            <th mat-header-cell *matHeaderCellDef>
                {{
                    "SPA.DEVICE.NAME" | translate
                }}
                <span class="resize-handle" (mousedown)="onResizeColumn($event, 'name')"></span>
            </th>
            <td mat-cell *matCellDef="let device" class="ellipsis-text">
                <span>{{device.name}}</span>
            </td>
        </ng-container>

        <ng-container matColumnDef="type">
            <th mat-header-cell *matHeaderCellDef>
                {{
                    "SPA.DEVICE.TYPE" | translate
                }}
                <span class="resize-handle" (mousedown)="onResizeColumn($event, 'type')"></span>
            </th>
            <td mat-cell *matCellDef="let device">
                <span>{{ 'ENUMS.STATION_TYPE.' + getStationType(device?.type) | translate }}</span>
            </td>
        </ng-container>

        <ng-container matColumnDef="code">
            <th mat-header-cell *matHeaderCellDef>
                {{
                    "SPA.DEVICE.ASSIGNMENT" | translate
                }}
                <span class="resize-handle" (mousedown)="onResizeColumn($event, 'code')"></span>
            </th>
            <td mat-cell *matCellDef="let device">
                <span class="hide-to-long" *ngIf="device.code[0] === '#'">{{ 'SPA.DEVICE.ASSIGNED' | translate }}</span>
                <span class="hide-to-long" *ngIf="device.code[0] !== '#'">{{device.code}}</span>
            </td>
        </ng-container>

        <ng-container matColumnDef="active">
            <th mat-header-cell *matHeaderCellDef class="text-center">
                {{
                    "SPA.DEVICE.STATUS" | translate
                }}
                <span class="resize-handle" (mousedown)="onResizeColumn($event, 'active')"></span>
            </th>
            <td mat-cell *matCellDef="let device" class="text-center">
                <div>
                    <span class="mr-2 hide-to-long">{{ 'COMMON.OFF' | translate }}</span>
                    <mat-slide-toggle [checked]="device.active" (click)="changeActive(device, $event)">
                        {{ 'COMMON.ON' | translate }}
                    </mat-slide-toggle>
                </div>
            </td>
        </ng-container>

        <ng-container matColumnDef="beat" *ngIf="showStationBeat()">
            <th mat-header-cell *matHeaderCellDef>
                {{
                    "SPA.DEVICE.BEAT" | translate
                }}
                <span class="resize-handle" (mousedown)="onResizeColumn($event, 'beat')"></span>
            </th>
            <td mat-cell *matCellDef="let device">
                <div>
                    <mat-form-field fxFlex="auto" appearance="outline" class="vex-flex-form-field" color="primary" style="width:60px">
                        <mat-label>
                            {{ 'SPA.LINES.HOURS' | translate }}
                        </mat-label>
                        <input matInput type="Number" [(ngModel)]="device.hour" (change)="leadingZeros($event)"
                        (keyup)="leadingZeros($event)"
                        (click)="leadingZeros($event)" min='0'>
                        <mat-error>{{ 'SPA.LINES.LINE_BEAT_ERROR' | translate }}</mat-error>
                    </mat-form-field>
                    <div class="dot-text">:</div>
                    <mat-form-field fxFlex="auto" appearance="outline" class="vex-flex-form-field" color="primary" style="width:60px">
                        <mat-label>
                            {{ 'SPA.LINES.MINUTES' | translate }}
                        </mat-label>
                        <input matInput type="Number" [(ngModel)]="device.minute" (change)="leadingZeros($event)"
                        (keyup)="leadingZeros($event)"
                        (click)="leadingZeros($event)" min='0'>
                        <mat-error>{{ 'SPA.LINES.LINE_BEAT_ERROR' | translate }}</mat-error>
                    </mat-form-field>
                    <div class="dot-text">:</div>
                    <mat-form-field fxFlex="auto" appearance="outline" class="vex-flex-form-field" color="primary" style="width:60px">
                        <mat-label>
                            {{ 'SPA.LINES.SECONDS' | translate }}
                        </mat-label>
                        <input matInput type="Number" [(ngModel)]="device.second" (change)="leadingZeros($event)"
                        (keyup)="leadingZeros($event)"
                        (click)="leadingZeros($event)" min='0'>
                        <mat-error>{{ 'SPA.LINES.LINE_BEAT_ERROR' | translate }}</mat-error>
                    </mat-form-field>
                </div>
                <button color="primary" mat-raised-button type="button" (click)="updateBeatDuration(device.id, ((device.minute * 60) + (device.second * 1) + (device.hour * 3600)))">
                    <mat-icon class="rtl:-mr-1" inline="true">save</mat-icon>
                </button>
            </td>
        </ng-container>

        <ng-container matColumnDef="actions">
            <th mat-header-cell *matHeaderCellDef class="text-center">
                {{ "SPA.DEVICE.ACTIONS" | translate }}
                <span class="resize-handle" (mousedown)="onResizeColumn($event, 'actions')"></span>
            </th>
            <td mat-cell *matCellDef="let device">
                <div class="column buttons buttons-actions">
                    <div fxLayout="row" fxLayout.lt-sm="column" fxLayoutGap="8px" fxLayoutGap.lt-sm="0">

                <button color="primary" mat-raised-button type="button" [disabled]="device.active"
                (click)="triggerAction(DeviceActionEnum.EDIT, device.id)">
                    <mat-icon class="ltr:-ml-1 rtl:-mr-1 rtl:ml-2" inline="true">edit</mat-icon>
                </button>
                <button color="warn" mat-raised-button type="button" [disabled]="device.active" (click)="delete(device)">
                    <mat-icon class="ltr:-ml-1 rtl:-mr-1 rtl:ml-2" inline="true">delete</mat-icon>
                </button>
                <button class="bg-blue" mat-raised-button type="button" (click)="reboot(device)">
                    <mat-icon class="ltr:-ml-1 rtl:-mr-1 rtl:ml-2" inline="true">power_settings_new</mat-icon>
                </button>
                <ng-container *apConfiguration="[ConfigurationEnum.REMOTE_STATION_VIEW]">
                    <ng-container *apUserAccess="[UserPermissionsEnum.RemoteStationView]">
                        <a href="/live-debugging?ip={{device.ip}}" target="_blank" *ngIf="showCastBtn(device)">
                            <button color="primary" mat-raised-button type="button">
                                <mat-icon class="ltr:-ml-1 rtl:-mr-1 rtl:ml-2" inline="true">cast</mat-icon>
                            </button>
                        </a>
                    </ng-container>
                </ng-container>
                                            
                <ng-container *apConfiguration="[ConfigurationEnum.STATION_UPDATE]">
                    <ng-container class="center-align" *ngIf="!isSuperAdmin()">
                        <button color="primary" mat-raised-button type="button" (click)="deviceUpdaterService.updateDevices(device.id)">
                            <mat-icon class="ltr:-ml-1 rtl:-mr-1 rtl:ml-2" inline="true">sync</mat-icon>
                        </button>
                    </ng-container>
                    <ng-container class="center-align" *ngIf="isSuperAdmin() && deviceUpdaterService.updaterEnabled()">
                        <button color="primary" mat-raised-button type="button"
                            (click)="deviceUpdaterService.runSuperAdminAction(device.id)">
                            <mat-icon class="ltr:-ml-1 rtl:-mr-1 rtl:ml-2 mr-3" inline="true">sync</mat-icon>
                            <mat-icon class="ltr:-ml-1 rtl:-mr-1 rtl:ml-2" inline="true">build</mat-icon>
                        </button>
                    </ng-container>
                </ng-container>
                </div>
                </div>
            </td>
        </ng-container>

        <ng-container matColumnDef="updates">
            <th mat-header-cell *matHeaderCellDef [resizeColumn]="false">
                <span class="resize-handle" (mousedown)="onResizeColumn($event, 'updates')"></span>
            </th>
            <td mat-cell *matCellDef="let device">
                <div class="column buttons update update-body" *apConfiguration="[ConfigurationEnum.STATION_UPDATE]">
                    <ng-container class="center-align" *ngIf="!isSuperAdmin()">
                        <button color="primary" mat-raised-button type="button" (click)="deviceUpdaterService.updateDevices(device.id)">
                            <mat-icon class="ltr:-ml-1 rtl:-mr-1 rtl:ml-2"
                                      inline="true">sync</mat-icon>
                        </button>
                    </ng-container>
                    <ng-container class="center-align" *ngIf="isSuperAdmin() && deviceUpdaterService.updaterEnabled()">
                        <button color="primary" mat-raised-button type="button" (click)="deviceUpdaterService.runSuperAdminAction(device.id)">
                            <mat-icon class="ltr:-ml-1 rtl:-mr-1 rtl:ml-2 mr-3"
                                      inline="true">sync</mat-icon>
                            <mat-icon class="ltr:-ml-1 rtl:-mr-1 rtl:ml-2"
                                      inline="true">build</mat-icon>
                        </button>
                    </ng-container>
                </div> 
            </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns" cdkDrag [cdkDragData]="row"></tr>
    </table>
</div>
<ngx-loading [show]="loading" [config]="{ backdropBorderRadius: '3px' }"></ngx-loading>

<button mat-fab class="ap-fab" (click)="triggerAction(DeviceActionEnum.ADD, null)" *apUserAccess="[UserPermissionsEnum.StationManagement]">
    <mat-icon>add</mat-icon>
</button>
