import { AfterViewInit, Component, ElementRef, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, QueryList, SimpleChanges, ViewChild, ViewChildren } from '@angular/core';
import * as _ from 'lodash';
import { TranslateService } from '@ngx-translate/core';
import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { DeviceTypeEnum } from '../../../../../../../common/device/enums/device-type.enum';
import { UserPermissionsEnum } from '../../../../../../../common/auth/enums/user-permissions.enum';
import { ConfigurationEnum } from '../../../../../../../common/configuration/enums/configuration.enum';
import { DeviceFullInterface } from '../../../../../../../common/device/interfaces/device-full.interface';
import { DeviceIpInterface } from '../../interfaces/device-ip.interface';
import { DeviceUpdaterService } from '../../services/device.updater.service';
import { DeviceService } from '../../services/device.service';
import { LiveDebuggingEnum } from '../../../../../../../common/shared/enums/live-debugging.enum';
import { LineBeatTypeEnum } from '../../../../../../../common/line/enums/line-beat-type.enum';
import { SocketMessageEnum } from '../../../../../../../common/socket/enums/socket-message.enum';
import { SocketService } from '../../../../../_services/socket.service';
import { LineInterface } from '../../../../../../../common/line/interfaces/line.interface';
import { AuthService } from '../../../../../auth/auth.service';
import { ModalsService } from '../../../../../../../common/shared/services/modals.service';
import { DeviceActionEnum } from '../../enums/device-action.enum';
import { Socket } from '../../../../../../../common/socket/models/socket.model';
import { SocketChannel } from '../../../../../../../common/socket/utils/socket-channel';
import { DeviceChangedSocket } from '../../../../../../../common/device/socket/device-changed.socket';
import { DeviceActiveStateChangeSocket } from '../../../../../../../common/device/socket/device-active-state-change.socket';
import { StationOrderTypeEnum } from '../../../../../../../common/line/enums/station-order-type.enum';
import { LineService } from '../../../../../line/line.service';
import { MatLegacyDialog } from '@angular/material/legacy-dialog';
import { BeatSelectionPopupComponent } from '../../../../../line/beat-selection-popup/beat-selection-popup.component';
import { first } from 'rxjs/operators';
import { MatLegacyTableDataSource as MatTableDataSource } from '@angular/material/legacy-table';
import { ResizeTableDirective } from '../../../../../shared/directives/resizeTable.directive';
import { MatTable } from '@angular/material/table';
import { TableColumnVisibilityService } from '../../../../../../../common/user-table-settings/services/table-column-visibility.service';
import { UserTableTypeEnum } from '../../../../../../../common/user-table-settings/enums/user-table-type.enum';
import { UserTablePlaceEnum } from '../../../../../../../common/user-table-settings/enums/user-table-place.enum';
import { ColumnMetaDataInterface } from '../../../../../../../common/user-table-settings/interfaces/column-meta-data.interface';
import { LineTypeEnum } from '../../../../../../../common/line/enums/line-type.enum';
import { timer } from 'rxjs';

export type DeviceClickAction = {action: DeviceActionEnum, deviceId: number};

@Component({
  selector: 'app-devices-list',
  templateUrl: './devices-list.component.html',
  styleUrls: ['./devices-list.component.scss']
})
export class DevicesListComponent implements OnInit, OnChanges, AfterViewInit, OnDestroy {
  UserPermissionsEnum = UserPermissionsEnum;
  DeviceActionEnum = DeviceActionEnum;
  ConfigurationEnum = ConfigurationEnum;
  loading = true;
  @Input() deviceType: DeviceTypeEnum;
  @Input() line: LineInterface;
  @Input() isAndon: boolean;
  @Output() clickAction: EventEmitter<DeviceClickAction> = new EventEmitter<DeviceClickAction>();
  @ViewChild('table') table: MatTable<any>;
  @ViewChild(ResizeTableDirective) resizeTableDirective: ResizeTableDirective;
  @ViewChildren('mat-header-cell') headerCells!: QueryList<ElementRef>;
  
  public columns: any[] = [
    { field: 'reorder', width: 20, },
    { field: 'name', width: 20, },
    { field: 'ip', width: 20, },
    { field: 'version', width: 20, },
    { field: 'type', width: 20, },
    { field: 'code', width: 20, },
    { field: 'active', width: 20, },
    { field: 'beat', width: 20, },
    { field: 'actions', width: 20, },
  ];
  public displayedColumns: string[] = ['reorder', 'version', 'ip', 'name', 'type', 'code', 'active', 'beat', 'actions'];
  dataSource = new MatTableDataSource<any>([]);
  lineType: string;
  lineColumnData: any = {};
  isColumnWidthUpdated: boolean = false;
  columnsData: ColumnMetaDataInterface[] = [
    {
      id: 'reorder',
      width: '11.11%',
      isVisible: true,
    },
    {
      id: 'version',
      width: '11.11%',
      isVisible: true,
    },
    {
      id: 'ip',
      width: '11.11%',
      isVisible: true,
    },
    {
      id: 'name',
      width: '11.11%',
      isVisible: true,
    },
    {
      id: 'type',
      width: '11.11%',
      isVisible: true,
    },
    {
      id: 'code',
      width: '11.11%',
      isVisible: true,
    },
    {
      id: 'active',
      width: '11.11%',
      isVisible: true,
    },
    {
      id: 'beat',
      width: '11.11%',
      isVisible: true,
    },
    {
      id: 'actions',
      width: '11.11%',
      isVisible: true,
    },
  ];

  private ipsReachables: DeviceIpInterface[] = [];
  private deviceTypes = DeviceTypeEnum;
  private socket: Socket;
  private socketSubs: string[] = [];
  constructor(
    public deviceUpdaterService: DeviceUpdaterService,
    public deviceService: DeviceService,
    private socketService: SocketService,
    private authService: AuthService,
    private modalsService: ModalsService,
    private translateService: TranslateService,
    private lineService: LineService,
    private matLegacyDialog: MatLegacyDialog,
    private tableColumnVisibilityService: TableColumnVisibilityService,
  ) {
  }

  ngOnInit() {
    this.socket = this.socketService.getSocket();

    this.deviceService.init(this.line?.id);

    this.getDevices();
    this.getColumns();

    this.socket.join(SocketChannel.DEVICE);
    this.socketSubs.push(this.socket.on(
      SocketMessageEnum.DEVICE_CHANGED,
      (data: DeviceChangedSocket) => this.handleDeviceChange(data)
    ));
    this.socketSubs.push(this.socket.on(
      SocketMessageEnum.DEVICE_ACTIVE_STATE_CHANGE,
      (data: DeviceActiveStateChangeSocket) => this.handleDeviceChange(data)
    ));
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (!this.lineType ) {
      if(changes?.line?.currentValue) {
        this.lineType = LineTypeEnum[this.line.typeEnum];
      } else if(this.isAndon) {
        this.lineType = 'ANDON';
      }
    }
  }

  ngAfterViewInit(): void {
     timer(100).subscribe(() => this.updateColumnsWidth());
  }

  ngOnDestroy() {
    this.socketSubs.forEach(s => this.socket.off(s));
    this.socket.leave(SocketChannel.DEVICE);
    this.deviceUpdaterService.destroy();
  }

  triggerAction(action: DeviceActionEnum, deviceId: number) {
    this.clickAction.emit({
      action: action,
      deviceId: deviceId,
    });
  }

  getStationType(type: string): string {
    return _.findKey(this.deviceTypes, item => item === type);
  }

  showList(): boolean {
    return this.dataSource.data.length > 0
  }

  isSuperAdmin(): boolean {
    return this.authService.isSuperAdmin();
  }

  drop(event: CdkDragDrop<DeviceFullInterface>) {

    const prevIndex = this.dataSource.data.findIndex((d) => d === event.item.data);
    moveItemInArray(this.dataSource.data, prevIndex, event.currentIndex);
    this.table.renderRows();

    let order = 1;

    _.forEach(this.dataSource.data, (station: DeviceFullInterface) => {
      station.order = order;
      order++;
    });

    this.deviceService.changeOrder(
      this.dataSource.data.map(s => {
        return {
          stationId: s.id,
          order: s.order
        };
      })
    ).subscribe(null, error => {
      moveItemInArray(this.dataSource.data, event.currentIndex, prevIndex);
      this.table.renderRows();
    });
  }

  disableDrag(): boolean {
    return !!!this.line || this.line.stationOrderTypeEnum !== StationOrderTypeEnum.SEQUENTIAL;
  }

  showStationBeat(): boolean {
    return !!this.line && this.line.beatTypeEnum === LineBeatTypeEnum.STATION_BASED_BEAT;
  }

  updateBeatDuration(deviceId, duration) {
    this.lineService
    .getActiveShiftsForLine(this.lineService.getSelectedLine())
    .subscribe(activeShift => {
        if (activeShift) {
          // Pop-Up open Code
          this.matLegacyDialog.open(BeatSelectionPopupComponent, {
            width: '600px',
          }).afterClosed().pipe(first()).subscribe(res => {
              if (!!res) {
                const type: boolean = res === 'currentShift' ? true : false;
                this.updateDeviceBeat(deviceId, duration, type);
              }
          });
        } else {
          this.updateDeviceBeat(deviceId, duration, false);
        }
    });
  }

  updateDeviceBeat(deviceId, duration, type = null) {
    this.deviceService.updateDeviceBeat(deviceId, duration, type);
  }

  modifyIpsData(station) {
    if (!station.ip) {
      return;
    }
    let found = false;
    _.forEach(this.ipsReachables, (ip: DeviceIpInterface) => {
      if (station.id === ip.stationId) {
        found = true;
        ip.isChecked = ip.isChecked && ip.ip == station.ip;
        ip.ip = station.ip;
      }
    });
    if (!found) {
      this.ipsReachables.push({
        ip: station.ip,
        isChecked: false,
        isReachable: false,
        stationId: station.id,
        isChecking: false
      })
    }
  }

  checkIpsForActiveStations() {
    _.forEach(this.ipsReachables, (ip: DeviceIpInterface) => {
      if (!ip.isChecked && !ip.isChecking){
        ip.isChecking = true;
        this.urlExists(`http://${ip.ip}:${LiveDebuggingEnum.PORT}`).then((result) => {
          ip.isChecked = true;
          ip.isReachable = result;
          ip.isChecking = false;
        })
      }
    });
  }

  showCastBtn(device: DeviceFullInterface): boolean {
    if (!device.ip) {
      return false;
    }

    const elem = _.find(this.ipsReachables, (item: DeviceIpInterface) => { return item.stationId === device.id});

    return elem && elem.isReachable;
  }

  urlExists(url) {
    return  fetch(url, {mode: "no-cors"}).then(res => true).catch(err => false)
  }

  async delete(device: DeviceFullInterface) {
    const content = this.translateService.instant('SPA.DEVICE.DELETE_MODAL.HEADER', {deviceName: device.name});
    const confirmation = await this.modalsService.confirm(content).toPromise();
    if (!confirmation) {
      return
    }
    this.deviceService.deleteDevice(device.id)
      .subscribe((response) => {
        this.getDevices();
      });
  }

  async reboot(device: DeviceFullInterface) {
    const header = this.translateService.instant('SPA.DEVICE.REBOOT_MODAL.HEADER');
    const confirmation = await this.modalsService.confirm(header).toPromise();

    if (!confirmation) {
      return;
    }

    this.deviceService.rebootDevice(device);
  }

  async changeActive(device: DeviceFullInterface, event: MouseEvent) {
    event.preventDefault();

    if (device.active) {
      const header = 'SPA.DEVICE.DEACTIVATE_MODAL.HEADER';
      const confirmation = await this.modalsService.confirm(header).toPromise();

      if (!confirmation) {
        return;
      }
    }

    this.deviceService.changeActive(device);
  }

  private getDevices() {
    this.deviceService.getDevices().subscribe((response) => {
      const res = response.map(s => {

        if (!!s?.lastUpdatedBeatValue) {
          s.beat = s.lastUpdatedBeatValue
        }
        let beat = s.beat;
        s.second = beat % 60 < 10?'0'+Math.floor(beat % 60):Math.floor(beat % 60);
        beat = beat / 60;
        s.minute = beat % 60 < 10?'0'+Math.floor(beat % 60):Math.floor(beat % 60);
        beat = Math.floor(beat / 60);
        s.hour = beat % 24 < 10?'0'+Math.floor(beat % 24):Math.floor(beat % 24);
        
        s.beat /= 60;
        
        this.modifyIpsData(s);
        return s;
      });
      this.dataSource = new MatTableDataSource<any>(res);
      this.deviceUpdaterService.init(this.dataSource.data);
      this.checkIpsForActiveStations();
      this.loading = false;
    }, () => {
      this.loading = false;
    });
  }

  private filterOutColumn(columnName: string) {
    this.displayedColumns = this.displayedColumns.filter(column => column !== columnName);
    this.columns = this.columns.filter(obj => obj.field !== columnName);
  }

  private getColumns() {
    if(!this.isSuperAdmin()) {
      this.filterOutColumn('ip'); 
    }

    if(!this.showStationBeat()) {
      this.filterOutColumn('beat'); 
    }

    if(this.disableDrag()) {
      this.filterOutColumn('reorder');
    }

    this.getColumnData();
  }

  leadingZeros(input) {
    
    if(!isNaN(input.target.value) && input.target.value.length === 1) {
      input.target.value = '0' + input.target.value;
    }else{
      input.target.value = input.target.value * 1;
      if(!isNaN(input.target.value) && input.target.value.length === 1) {
        input.target.value = '0' + input.target.value;
      }
    }
  }

  private handleDeviceChange(data: DeviceChangedSocket | DeviceActiveStateChangeSocket) {
    if (this.line && (!data.lineId || this.line.id !== data.lineId)) {
      return;
    }

    if (!this.line && !!data.lineId) {
      return;
    }

    this.getDevices();
  }

  onResizeColumn(event: any, column: string) {
    const index = _.findIndex(this.displayedColumns, (item) => item === column);
    this.resizeTableDirective.onResizeColumn(event, index);
  }

  // Filter and update visible column data and width
  getColumnData(): void {
    this.tableColumnVisibilityService.loadTableMetaData(UserTableTypeEnum.STATION_LISTING, UserTablePlaceEnum.SPA).subscribe((result: any) => {
        if (!result) return;

        this.lineColumnData = result;
        // Retrieve and filter columns data for the current line type
        const columnsData = result[this.lineType] || [];
        if (columnsData.length) {
          this.columnsData = columnsData.filter(column => this.displayedColumns.includes(column.id));
          this.updateColumnsWidth();
        }
    });
  }

  // Save Column Data
  saveColumnData(): void {
    const data = {
        tableTypeEnum: UserTableTypeEnum.STATION_LISTING,
        placeEnum: UserTablePlaceEnum.SPA,
        columns: {
            ...this.lineColumnData,
            [this.lineType]: this.columnsData
        }
    };

    this.tableColumnVisibilityService.saveTableMetaData(data).subscribe(() => { });
  }

  // On Column resized update column data 
  onColumnDataChanged(updatedColumns: any[]): void {
    const totalWidth = updatedColumns.reduce((sum, item) => sum + item.width, 0);

    this.columnsData = this.columnsData.map(columnData => {
      const currentField = updatedColumns.find(el => el.field === columnData.id);
      if (currentField) {
        // Convert width proportionally to percentage
        columnData.width = ((currentField.width / totalWidth) * 100).toFixed(2) + '%';
      }
      return columnData;
    });

    this.saveColumnData();
  }

  // Update each column width based on current data
  updateColumnsWidth() {
    if(this.isColumnWidthUpdated) return;

    const tableListEl = document.querySelector('.device-list-table') as HTMLElement;
    if (!tableListEl) return;
    
    const tableWidth = tableListEl.offsetWidth;
    if (!tableWidth) return;

    this.columnsData.forEach(column => {
        const colWidthValue = this.getColumnWidth(tableWidth, column.width);
        const resizeColumn = this.resizeTableDirective.columns.find(el => el.field === column.id);

        if (resizeColumn) {
          resizeColumn.width = colWidthValue;
        }

        // Apply calculated width to all matching column elements
        document.querySelectorAll<HTMLElement>(`.device-list-table .mat-column-${column.id}`)
          .forEach(el => (el.style.width = `${colWidthValue}px`));
    });
    this.isColumnWidthUpdated = true;
  }

  // Calculates column width in pixels based on table width
  getColumnWidth(tableWidth: number, colWidth: string): number {
      const widthPercentage = parseFloat(colWidth);
      return widthPercentage ? +(tableWidth * (widthPercentage / 100)).toFixed(2) : 0;
  }
}
