import { Injectable } from '@angular/core';
import { SwPush } from '@angular/service-worker';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { PlatformType } from '../../../common/push-notification/enums/platform-type.enum';
import { PushNotificationSubscriptionInterface } from '../../../common/push-notification/interfaces/push-notification-subscription.interface';
import { InfoboxService } from '../../../common/shared/services/infobox.service';

@Injectable()
export class PushNotificationService {
  constructor(
    private swPush: SwPush,
    private http: HttpClient,
    private infoboxService: InfoboxService,
  ) {
    this.subscribeToMessages();
    this.subscribeToNotificationClicks();
  }

  subscribeToPushNotifications() {
    if (this.swPush.isEnabled) {
      this.requestPermission();
    } else {
      console.error('Service Worker is not enabled');
      this.infoboxService.error('SPA.PUSH_NOTIFICATION.SERVICE_WORKER_NOT_ENABLED');
    }
  }

  private requestPermission() {
    Notification.requestPermission().then(permission => {
       if (permission === 'granted') {
         this.requestSubscription();
       } else {
        console.warn('Permission not granted for Notifications');
        this.infoboxService.warning('SPA.PUSH_NOTIFICATION.PERMISSION_NOT_GRANTED');
       }
    }).catch(err => {
      console.error('Error requesting permission: ', err);
      this.infoboxService.error('SPA.PUSH_NOTIFICATION.REQUEST_PERMISSION_ERROR');
    });
  }

  private requestSubscription() {
    this.swPush.subscription.subscribe((subscription) => {
      if(!subscription) {
        this.swPush.requestSubscription({
          serverPublicKey: environment.VAPILD_PUBLIC_KEY
       }).then((sub: any) => {
          this.saveSubscription({
            token: sub,
            type: PlatformType.WEB,
            deviceId: null
          });
       }).then(() => {
          this.infoboxService.success('SPA.PUSH_NOTIFICATION.NOTIFICATIONS_SUBSCRIBED');
       }).catch((err: any) => {
          console.error('Error subscribing notifications: ', err);
          this.infoboxService.error('SPA.PUSH_NOTIFICATION.NOTIFICATIONS_SUBSCRIBED_ERROR');
       });
      }
    });
  }

  unsubscribeFromPushNotifications() {
    this.swPush
      .unsubscribe()
      .then(() => {
        this.infoboxService.success('SPA.PUSH_NOTIFICATION.NOTIFICATIONS_UNSUBSCRIBED');
      })
      .catch(error => {
        console.error('Error unsubscribing from push notifications: ', error);
        this.infoboxService.success('SPA.PUSH_NOTIFICATION.NOTIFICATIONS_UNSUBSCRIBED_ERROR');
      });
  }

  private saveSubscription(sub: PushNotificationSubscriptionInterface): any {
    return this.http.post<any>(`/api/push-notification/subscribe`, sub).subscribe(() => {});
  }

  private subscribeToMessages() {
    this.swPush.messages.subscribe((message: any) => {
    })
  }

  private subscribeToNotificationClicks() {
    this.swPush.notificationClicks.subscribe(({ action, notification }) => {
    });
  }
} 